import { OrderRequest } from "../features/payment/mutations/submitOrderMutation";
import { PriceMemberpackRequest } from "../features/delivery/shipping/mutations/pricedMemberpackMutation";
import {
  FormField,
  FormProperty,
  SanitizeMode,
} from "../config/next-gen-config-wip";

import { PersonalInfoRequest } from "../features/personal-info/mutations/model";

type InputValue = string | null | undefined;

export const sanitizeStringValue = (value: InputValue = ""): string => {
  return value
    ? value.replace(/[^\p{L}\-'‘`\s]+/gu, "").replace(/\s+/g, " ")
    : "";
};

export const sanitizeValueOnChange = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => {
  e.target.value = sanitizeStringValue(e.target.value);
};

export const customSanitizeValueOnChange = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  sanitize: (value: string) => string
) => {
  e.target.value = sanitize(e.target.value);
};

export const sanitizeNumberValue = (value: InputValue = ""): string => {
  return value ? value.replace(/[^0-9]/gu, "") : "";
};

export const sanitizeWithoutNumbers = (value: InputValue = ""): string => {
  return value ? value.replace(/\d/g, "") : "";
};

export const sanitizeValue = (value: InputValue, replacement = ""): string => {
  if (!value) {
    return "";
  }

  return value
    .replace(/[^\p{L}\-'`\s0-9]+/gu, "")
    .replace(/\s{2,}/g, " ")
    .replace(/^\s+/, replacement);
};

export const sanitizePickupFullName = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  country: string
) => {
  switch (country) {
    case "id-ID":
    case "fr-FR":
      return customSanitizeValueOnChange(e, () =>
        e.target.value.replace(/[^a-zA-Z\s]/g, "")
      );
    default:
      return sanitizeValueOnChange(e);
  }
};

export const sanitizePayload = <
  T extends PersonalInfoRequest | OrderRequest | PriceMemberpackRequest,
>(
  payload: T,
  formConfig: Partial<FormProperty>
): T => {
  const fieldsToSanitize = Object.values(formConfig)
    .filter((field: FormField) =>
      field?.sanitize?.find(
        (sanitizer) => sanitizer.mode === SanitizeMode.ON_SUBMIT
      )
    )
    .map((field: FormField) => ({
      name: field.name,
      sanitize: field.sanitize!.find(
        (sanitizer) => sanitizer.mode === SanitizeMode.ON_SUBMIT
      )!.sanitizeFn,
    }));

  const sanitizedPayload = fieldsToSanitize.reduce(
    (accPayload, { name, sanitize }) => {
      const value = (accPayload as { [key: string]: string })[name];

      return {
        ...accPayload,
        [name]: sanitize(value),
      };
    },
    payload
  );

  return sanitizedPayload;
};
