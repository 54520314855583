import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { WithTypeContaining } from "../../../types/utilityTypes";

export type HomeAddressState = {
  province: string;
  state: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  district: string;
  subdistrict: string;
};

type HomeAddressAction = {
  setHomeAddress: (
    personalInfoValues: WithTypeContaining<HomeAddressState>
  ) => void;
  reset: () => void;
};

const initialState: HomeAddressState = {
  province: "",
  state: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  postalCode: "",
  district: "",
  subdistrict: "",
};

export const useHomeAddressStore = create<
  HomeAddressState & HomeAddressAction
>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setHomeAddress: (personalInfoValues) => {
          set(createHomeAddressFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "home-address-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const homeAddressFromStore = () => {
  return {
    province: useHomeAddressStore.getState().province,
    state: useHomeAddressStore.getState().state,
    addressLine1: useHomeAddressStore.getState().addressLine1,
    addressLine2: useHomeAddressStore.getState().addressLine2,
    city: useHomeAddressStore.getState().city,
    postalCode: useHomeAddressStore.getState().postalCode,
    district: useHomeAddressStore.getState().district,
    subdistrict: useHomeAddressStore.getState().subdistrict,
  };
};

export const createHomeAddressFromForm = ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode,
  district,
  subdistrict,
  state,
}: WithTypeContaining<HomeAddressState>): HomeAddressState => ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode: postalCode.toUpperCase(),
  district,
  subdistrict,
  state,
});
