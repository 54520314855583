import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { CountryFormConfiguration } from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
} from "./defaults";
import { queryClient } from "../queryClient";
import { VisibleOnRule } from "../model/VisibleOnRule";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import { DsaLogo } from "../../components/common/Logo/models/Logo";
import { AdministrativeAreasResponse, avsApi } from "../../api/avs";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

const TAIWAN_NAME_PATTERN = RegExp(
  /^[\u4e00-\u9fff\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u,
  "u"
);
const ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS = new RegExp(
  /^[\u4e00-\u9fff\p{Lower}\p{Upper}\s\-,./'’#()@\d]*$/u,
  "u"
);

const getAddressData = async (areas: string[] = []) =>
  await queryClient.fetchQuery({
    queryKey: ["zh-TW", areas],
    queryFn: async (): Promise<AdministrativeAreasResponse> =>
      await avsApi.getAdministrativeAreas("zh-TW", areas, 0, 100),
    staleTime: Infinity,
    gcTime: Infinity,
  });

const getAddresses = async (areas: string[]) =>
  (await getAddressData(areas)).values;

const getStates = async (): Promise<string[]> => await getAddresses([]);

const getCities = async (selectedState: string): Promise<string[]> =>
  await getAddresses([selectedState]);

const getPostalCodes = async (
  selectedState: string,
  selectedCity: string
): Promise<string[]> => await getAddresses([selectedState, selectedCity]);

export const Taiwan: CountryFormConfiguration = {
  id: "zh-TW",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        bottomDescription: {
          rule: {
            visibleOn: ["beenMemberBefore", Boolean] as VisibleOnRule<boolean>,
          },
          text: "PREVIOUS_MEMBERSHIP_DESCRIPTION",
          individualStyling: ["centeredAndBold"],
        },
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: TAIWAN_NAME_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: TAIWAN_NAME_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      state: {
        name: "state",
        label: "STATE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: getStates,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["state"],
        getOptions: getCities,
        rule: {
          visibleOn: ["state", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["state", "city"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(100, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: /^[a-zA-Z][1-2][0-9]{8}$/,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".jpg", ".jpeg", ".png", ".pdf"],
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 53, errorMessage: "HMP_CARE_OF_NAME_SIZE" },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      state: {
        name: "state",
        label: "STATE",
        type: "asyncAutocomplete",
        getOptions: getStates,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        dependenciesNames: ["state"],
        getOptions: getCities,
        rule: {
          visibleOn: ["state", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        dependenciesNames: ["state", "city"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
  cityLength: 40,
  postalCodeLength: 3,
  postalCodeMask: "000",
  postalCodePattern: /^[0-9]{3}$/,
  postalCodeRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Taiwan",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLinks: ["PRIVACY", "COOKIE_POLICY", "RULES_OF_CONDUCT", "COPYRIGHT"],
  footerLogos: [DsaLogo],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  showDataPrivacyAcknowledgment: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["tw"],
  defaultCountryCode: "+886|Taiwan",
} as const;
