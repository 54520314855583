import Cookies from "js-cookie";

const ANALYTICS_AGREEMENT_GROUP_ACCEPTED = "C0002:1";
const CONSENTS_GROUP_COOKIE = "SSO-ConsentGroup";
const OA_TOKEN_COOKIE_NAME = "OA-Token";

const getConsentGroups = () => Cookies.get(CONSENTS_GROUP_COOKIE);

export const getOaTokenCookie = () => Cookies.get(OA_TOKEN_COOKIE_NAME);

export const removeTokenCookie = () => Cookies.remove(OA_TOKEN_COOKIE_NAME);

export const isAnalyticsPermittedByUser = () =>
  getConsentGroups()
    ?.split(",")
    .some((consent) => consent.includes(ANALYTICS_AGREEMENT_GROUP_ACCEPTED));

export const cookieBannerWasAlreadyShownOnSSO = () =>
  getConsentGroups()
    ?.split(",")
    .some((consent) => consent.split(":")[1] === "1");
