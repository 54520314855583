import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  Gender,
} from "../constants";
import { defaultForbiddenWordsValidation } from "./defaults";
import { DsaLogo } from "../../components/common/Logo/models/Logo";
import dayjs from "dayjs";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import { IdentityConfirmationState } from "../../features/personal-info/tins/tinsStore";
import { VisibleOnRule } from "../model/VisibleOnRule";
import { staticAssetsApi } from "../../api/staticAssets";

import { SanitizeMode } from "../next-gen-config-wip";

const countries = async (): Promise<string[]> =>
  await staticAssetsApi.getStaticAssets<string[]>("fr-FR", "countries.json");

export const France: CountryFormConfiguration = {
  id: "fr-FR",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-ZÀ-ÿ‘\-/' \s]/g, ""),
          },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: 30,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u, "u"),
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-ZÀ-ÿ‘\-/' \s]/g, ""),
          },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: 30,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u, "u"),
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "MIDDLE_INITIAL",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-ZÀ-ÿ‘\-/' \s]/g, ""),
          },
        ],
        validation: {
          required: {
            value: false,
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          maxLength: {
            value: 20,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u, "u"),
            errorMessage: "REQUIRED",
          },
          trim: true,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "radio",
        options: [
          { value: Gender.MALE, label: "MALE" },
          { value: Gender.FEMALE, label: "FEMALE" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        validationErrorStyling: ["textCentered"],
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      placeOfBirth: {
        name: "placeOfBirth",
        label: "PLACE_OF_BIRTH",
        type: "object",
        validation: {
          required: { value: false },
        },
        rule: {
          isHidden: true,
        },
        fields: {
          country: {
            name: "placeOfBirthCountry",
            label: "PLACE_OF_BIRTH_COUNTRY",
            type: "asyncAutocomplete",
            getOptions: countries,
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
          },
          city: {
            name: "placeOfBirthCity",
            label: "PLACE_OF_BIRTH_CITY",
            type: "text",
            sanitize: [
              {
                mode: SanitizeMode.ON_CHANGE,
                sanitizeFn: (value) => value.replace(/[^a-zA-ZÀ-ÿ‘ -]/g, ""),
              },
            ],
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
              maxLength: {
                value: 40,
                errorMessage: "TOO_LONG",
              },
              hasNoOnlySpecialSigns: {
                value: true,
                errorMessage: "REQUIRED",
              },
            },
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        defaultValue: "",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 15,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 15,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: /^[0-9]+$/,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
        rule: {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState !== IdentityConfirmationState.FAILED,
          ] as VisibleOnRule<IdentityConfirmationState>,
          keepValueOnHidden: false,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState === IdentityConfirmationState.FAILED,
          ] as VisibleOnRule<IdentityConfirmationState>,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/u, "u"),
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/u, "u"),
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        defaultValue: "",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^(0[1-9]\d{3}|[1-8]\d{4}|969\d{2}|97[1-4]\d{2}|976\d{2})$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
      prospectOwnsSignificantSocialMediaAccount: {
        name: "prospectOwnsSignificantSocialMediaAccount",
        label: "SOCIAL_MEDIA_FOLLOWERS_QUESTION",
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      socialMediaConsents: {
        name: "socialMediaConsentsWrapper",
        label: "SOCIAL_MEDIA",
        type: "object",
        classNames: ["collapse"],
        rule: {
          visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
        },
        validation: {
          required: { value: false },
        },
        isCollapsable: true,
        fields: {
          customerAcknowledgementInfo: {
            name: "customerAcknowledgementInfo",
            type: "static",
            staticValue: "CUSTOMER_ACKNOWLEDGEMENT_INFO",
          },
          socialMediaConsent0: {
            name: "socialMediaConsent0",
            label: "CUSTOMER_SERVICE",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CONTACT_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent1: {
            name: "socialMediaConsent1",
            label: "CUSTOMER_LEAD",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CUSTOMER_LEAD_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent2: {
            name: "socialMediaConsent2",
            label: "SALES_AND_MARKETING_PLAN",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SALES_AND_MARKETING_PLAN_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent3: {
            name: "socialMediaConsent3",
            label: "NO_ASSOCIATION",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "NO_ASSOCIATION_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent4: {
            name: "socialMediaConsent4",
            label: "SOCIAL_MEDIA",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SOCIAL_MEDIA_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent5: {
            name: "socialMediaConsent5",
            label: "EARNINGS_CLAIMS",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "EARNINGS_CLAIMS_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaEndingNote: {
            name: "socialMediaEndingNote",
            type: "static",
            staticValue: "SOCIAL_MEDIA_ENDING_NOTE",
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-ZÀ-ÿ‘\s]/g, ""),
          },
        ],
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          maxLength: {
            value: 61,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
          pattern: {
            value: /^[A-Za-z ]*$/,
            errorMessage: "REQUIRED",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/u, "u"),
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          pattern: {
            value: RegExp(/^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/u, "u"),
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        defaultValue: "",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^(0[1-9]\d{3}|[1-8]\d{4}|969\d{2}|97[1-4]\d{2}|988\d{2})$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
    },
  },
  addressLength: 40,
  footerLinks: ["PRIVACY", "COOKIE_POLICY", "RULES_OF_CONDUCT", "COPYRIGHT"],
  footerLogos: [DsaLogo],
  showHmpBenefits: true,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["fr", "gp", "mq", "yt", "nc", "gf"],
  defaultCountryCode: "+33|France",
  showSponsorshipDisclaimer: true,
} as const;
