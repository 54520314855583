import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useProfileStore } from "./profileStore";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../config/countries/countryUtil";

export const useDefaultPhoneCountryCode = (
  setValue: UseFormSetValue<{ countryCode: string; phoneNumber: string }>
) => {
  const country = useProfileStore(useShallow((state) => state.country));
  const defaultCountryCode = getCountryConfig(country)?.defaultCountryCode;
  const supportedCountryCodes: string[] = getCountryConfig(country)
    ?.supportedCountryCodes as string[];

  useEffect(() => {
    if (defaultCountryCode) {
      setValue("countryCode", defaultCountryCode as string);
    }
  }, [country]);

  return defaultCountryCode && supportedCountryCodes?.length <= 1;
};
