import { ComponentType, lazy, LazyExoticComponent } from "react";

// Typing's coming directly from react library, that's why ComponentType<any> used
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type PreloadableComponent<T extends ComponentType<any>> =
  LazyExoticComponent<T> & {
    preload: () => Promise<void>;
  };
/**
 * Lets you preload suspended component.
 *
 * @see react components lazy loading {@link https://react.dev/reference/react/lazy React Docs}
 *
 * @param load A function that returns a `Promise` or another thenable (a `Promise`-like object with a
 * then method). React will not call `load` until the first time you attempt to render the returned
 * component or until .preload() function is directly called on the returned component constructor.
 * So when you expect to use component soon, you can use Component.preload() function to prefetch it.
 *
 * @example
 *
 * ```tsx
 * import { lazyWithPreload } from './lazyWithPreload';
 *
 * const PreloadableComponent =
 *     lazyWithPreload(() => import("./PreloadableComponent"));
 *
 * const handleOnMouseOver(() => PreloadableComponent.preload());
 * ```
 */
export const lazyWithPreload = <T extends ComponentType<T>>(
  load: () => Promise<{ default: T }>
): PreloadableComponent<T> => {
  let Component: LazyExoticComponent<T>;
  try {
    Component = lazy(load);
  } catch {
    window.location.reload();
    throw new Error("Lazy loading error");
  }

  (Component as PreloadableComponent<T>).preload = async () => {
    await load();
  };

  return Component as PreloadableComponent<T>;
};
