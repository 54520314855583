import * as yup from "yup";
import { StringSchema } from "yup";

import { getCountryConfig } from "../../../../config/countries/countryUtil";
import { ONLY_SPECIAL_SIGNS_REGEX } from "../../../../config/constants";

export const postalCodeValidation = (country: string) => {
  return yup.string().test("postal-code-validation", function validate(value) {
    const { path, createError } = this;
    if (
      value &&
      !(getCountryConfig(country)?.postalCodePattern as RegExp)?.test(value)
    ) {
      return createError({ path, message: "POSTAL_CODE_SIZE" });
    }
    return true;
  });
};

export function checkHasNoOnlySpecialSigns(
  this: StringSchema,
  errorMessage: string
) {
  return this.test(
    "not-only-special-signs",
    errorMessage,
    (value) => !ONLY_SPECIAL_SIGNS_REGEX.test(value || "")
  );
}
