import React, { useEffect } from "react";
import { Link, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import * as Sentry from "@sentry/browser";
import { parseJwt } from "../../utils/jwt";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useShallow } from "zustand/react/shallow";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const token = useAuthenticationStore(useShallow((state) => state.token));
  const country = useProfileStore(useShallow((state) => state.country));

  useEffect(() => {
    const pageNotFoundError = new Error(
      `Page not found: 404 error at ${location.pathname}`
    );
    pageNotFoundError.name = "Page not found";

    Sentry.setTag("registrationId", parseJwt(token).registrationId);
    if (country) {
      Sentry.setTag("country", country);
    }
    Sentry.captureException(pageNotFoundError);
  }, [location]);

  return (
    <div className={styles.notFoundPage}>
      <section
        className={styles.notFoundContainer}
        data-testid="not-found-container"
      >
        <span>4</span>
        <span>
          <span className={styles.transformedDigit}>0</span>
        </span>
        <span>4</span>
        <h3>{t("PAGE_NOT_FOUND")}</h3>
      </section>
      <Link to="/register">{t("GO_HOME")} </Link>
    </div>
  );
};

export default NotFoundPage;
