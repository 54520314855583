import { CountryFormConfiguration } from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
} from "./defaults";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { VisibleOnRule } from "../model/VisibleOnRule";
import {
  DsaMyLogo,
  FpxLogo,
  MastercardLogo,
  VisaLogo,
} from "../../components/common/Logo/models/Logo";
import { queryClient } from "../queryClient";
import { AdministrativeAreasResponse, avsApi } from "../../api/avs";
import dayjs from "dayjs";
import { SanitizeMode } from "../next-gen-config-wip";

const ALPHABETS_AND_SPECIAL_CHARS = new RegExp(/^[a-zA-Z@\-.,/'#\s]*$/);
const ALPHANUMERIC_AND_SPECIAL_CHARS = new RegExp(/^[a-zA-Z0-9@\-.,/'#\s]*$/);
const ALPHANUMERIC = new RegExp(/^[a-zA-Z0-9\s]*$/);

const getAddresses = async (locale: string, areas: string[] = []) =>
  await queryClient.fetchQuery({
    queryKey: [locale, areas],
    queryFn: async (): Promise<AdministrativeAreasResponse> =>
      await avsApi.getAdministrativeAreas(locale, areas, 0, 100),
    staleTime: Infinity,
    gcTime: Infinity,
  });

const getStatesLabels = async (locale: string) => {
  const states = await getAddresses(locale);
  return states.values;
};

const ethnicities = [
  { value: "CHINESE", label: "CHINESE_ETHNICITY" },
  { value: "MALAY", label: "MALAY_ETHNICITY" },
  { value: "INDIAN", label: "INDIAN_ETHNICITY" },
  { value: "OTHER", label: "OTHER_ETHNICITY" },
];

export const Malaysia = (locale: string): CountryFormConfiguration => {
  return {
    id: locale,
    forms: {
      personalInfo: {
        beenMemberBefore: {
          name: "beenMemberBefore",
          label: "PREVIOUS_MEMBERSHIP",
          labelExtraClassNames: ["enlargedLabelText"],
          type: "radio",
          options: [
            { value: true, label: "YES" },
            { value: false, label: "NO" },
          ],
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
          },
          classNames: ["centerJustified"],
          bottomDescription: {
            rule: {
              visibleOn: [
                "beenMemberBefore",
                Boolean,
              ] as VisibleOnRule<boolean>,
            },
            text: "PREVIOUS_MEMBERSHIP_DESCRIPTION",
            individualStyling: ["centeredAndBold"],
          },
          validationErrorStyling: ["textCentered"],
        },
        lastName: {
          name: "lastName",
          label: "LAST_NAME",
          type: "text",
          sanitize: [
            {
              mode: SanitizeMode.ON_CHANGE,
              sanitizeFn: (value) => value.replace(/[^a-zA-Z@\-.,/'#\s]/g, ""),
            },
          ],
          bottomDescription: { text: "LAST_NAME_DISCLAIMER" },
          topDescription: { text: "LAST_NAME_TOP_DISCLAIMER" },
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "LAST_NAME_SIZE",
            },
            maxLength: {
              value: defaultCountryConfigFieldLengths.lastName,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHABETS_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        firstName: {
          name: "firstName",
          label: "FIRST_NAME",
          type: "text",
          sanitize: [
            {
              mode: SanitizeMode.ON_CHANGE,
              sanitizeFn: (value) => value.replace(/[^a-zA-Z@\-.,/'#\s]/g, ""),
            },
          ],
          bottomDescription: { text: "FIRST_NAME_DISCLAIMER" },
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "FIRST_NAME_SIZE",
            },
            maxLength: {
              value: defaultCountryConfigFieldLengths.firstName,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHABETS_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        ethnicity: {
          name: "ethnicity",
          label: "ETHNICITY",
          type: "dropdown",
          options: ethnicities,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
          },
        },
        addressLine1: {
          name: "addressLine1",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: defaultForbiddenWordsValidation,
            trim: true,
          },
        },
        addressLine2: {
          name: "addressLine2",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: defaultForbiddenWordsValidation,
            trim: true,
          },
        },
        city: {
          name: "city",
          label: "CITY",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 30,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        state: {
          name: "state",
          label: "STATE",
          type: "asyncAutocomplete",
          getOptions: async () => await getStatesLabels(locale),
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        postalCode: {
          name: "postalCode",
          label: "POSTAL_CODE",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: { value: 5, errorMessage: "TOO_LONG" },
            minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
            pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
          },
        },
        dateOfBirth: {
          name: "dateOfBirth",
          label: "BIRTHDAY",
          type: "date",
          bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxDate: {
              value: dayjs().subtract(18, "year"),
              errorMessage: "TOO_YOUNG",
            },
            minDate: {
              value: dayjs().subtract(100, "year"),
              errorMessage: "TOO_OLD",
            },
            mask: "YYYY/MM/DD",
          },
        },
        registrationNumber0: {
          name: "registrationNumber0",
          label: "REGISTRATION_NUMBER0",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 12,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            minLength: {
              value: 12,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            pattern: {
              value: /^([0-9]{6}-[0-9]{2}-[0-9]{4})$/,
              errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
            },
            trim: true,
            mask: "000000-00-0000",
          },
        },
        registrationNumber1: {
          name: "registrationNumber1",
          label: "REGISTRATION_NUMBER1",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 15,
              errorMessage: "REGISTRATION_NUMBER_SIZE1",
            },
            minLength: {
              value: 15,
              errorMessage: "REGISTRATION_NUMBER_SIZE1",
            },
            pattern: {
              value: /^[A-Za-z][0-9]{2}-[0-9]{2}(0[1-9]|1[0-2])-[0-9]{8}$/,
              errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN1",
            },
            trim: true,
            mask: "a00-0000-00000000",
          },
        },
        registrationNumber0Attachment0: {
          name: "registrationNumber0-attachment0",
          label: "UPLOAD_FILE_INFO_RESIDENT0",
          type: "file",
          index: 1,
          attachmentType: AttachmentType.TAX_NUMBER,
          middleDescription: {
            text: "REGISTRATION_DOCUMENT0_MIDDLE_DESCRIPTION",
          },
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
          },
        },
        dataPrivacy: {
          name: "dataPrivacy",
          header: "DATA_PRIVACY_HEADER",
          type: "static",
          staticValue: "DATA_PRIVACY_STATEMENT",
        },
        dataPrivacyDisclosure: {
          name: "dataPrivacyDisclosure",
          type: "static",
          staticValue: "INFORMATION_DISCLOSURE",
        },
        additionalComunicationConsent: {
          name: "additionalComunicationConsent",
          type: "static",
          staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
        },
        marketingConsentAccepted: {
          name: "marketingConsentAccepted",
          label: "EXTRA_COMMUNICATION_CONSENT",
          type: "checkbox",
          validation: {
            required: { value: false },
          },
        },
      },
      shippingAddress: {
        careOfName: {
          name: "careOfName",
          label: "HMP_CARE_OF_NAME",
          type: "text",
          sanitize: [
            {
              mode: SanitizeMode.ON_CHANGE,
              sanitizeFn: (value) => value.replace(/[^a-zA-Z@\-.,/'#\s]/g, ""),
            },
          ],
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 53,
              errorMessage: "HMP_CARE_OF_NAME_SIZE",
            },
            pattern: {
              value: ALPHABETS_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "REQUIRED",
            },
            trim: true,
          },
        },
        addressLine1: {
          name: "addressLine1",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: defaultForbiddenWordsValidation,
            trim: true,
          },
        },
        addressLine2: {
          name: "addressLine2",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: defaultForbiddenWordsValidation,
            trim: true,
          },
        },
        city: {
          name: "city",
          label: "CITY",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 30,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        state: {
          name: "state",
          label: "STATE",
          type: "asyncAutocomplete",
          getOptions: async () => await getStatesLabels(locale),
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        postalCode: {
          name: "postalCode",
          label: "POSTAL_CODE",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: { value: 5, errorMessage: "TOO_LONG" },
            minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
            pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
          },
        },
      },
    },
    addressLength: 40,
    cityLength: 30,
    postalCodeLength: 5,
    postalCodeMask: false,
    postalCodePattern: /^\d{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultCountryConfigFieldLengths.province,
    firstNameLength: defaultCountryConfigFieldLengths.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultCountryConfigFieldLengths.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
    mothersMaidenNameRequired: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
    showOrderHandlerInformation: false,
    showSponsorshipDisclaimer: true,
    footerLinks: ["PRIVACY", "COOKIE_POLICY", "RULES_OF_CONDUCT", "COPYRIGHT"],
    footerLogos: [DsaMyLogo, VisaLogo, MastercardLogo, FpxLogo],
    supportedCountryCodes: ["my"],
    defaultCountryCode: "+60|Malaysia",
  } as const;
};
