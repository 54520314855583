import axios from "axios";

export type AdministrativeAreasResponse = {
  page: number;
  totalPages: number;
  totalElements: number;
  values: string[];
};

const getAdministrativeAreas = async (
  locale: string,
  areas: string[],
  page: number,
  size: number
): Promise<AdministrativeAreasResponse> => {
  const areasParam =
    areas.length > 0 ? `areas=${encodeURIComponent(areas.join("|"))}&` : "";
  const url = `/v1/administrative-areas/${locale}?${areasParam}page=${page}&size=${size}`;

  const response = await axios.get<AdministrativeAreasResponse>(url);
  return response.data;
};

export const avsApi = {
  getAdministrativeAreas,
};
