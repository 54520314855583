import { lazyWithPreload } from "../../utils/lazyLoading";

export const PaymentPage = lazyWithPreload(
  () => import("../../pages/Payment/PaymentPage")
);

export const PaymentStatusPage = lazyWithPreload(
  () => import("../../pages/PaymentStatus/PaymentStatus")
);

export const ConfirmationPage = lazyWithPreload(
  () => import("../../pages/Confirmation/ConfirmationPage")
);

export const NewConfirmationPage = lazyWithPreload(
  () => import("../../pages/NewConfirmation/NewConfirmationPage")
);

export const FiluetConfirmation = lazyWithPreload(
  () => import("../../pages/FiluetConfirmation/FiluetConfirmation")
);

export const OrderConfirmationPage = lazyWithPreload(
  () => import("../../pages/OrderConfirmation/OrderConfirmation")
);
