import { CountryFormConfiguration } from "../constants";
import dayjs from "dayjs";
import { defaultForbiddenWordsValidation } from "./defaults";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

export const Thailand: CountryFormConfiguration = {
  id: "th-TH",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: { required: { value: true, errorMessage: "REQUIRED" } },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: 20,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘]*$/u,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: 30,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘]*$/u,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(20, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 13,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 13,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: /^([0-9]{13})$/u,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
          mask: "0000000000000",
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 30,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 30,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: () => ["WOLOLO", "HOHOHO"],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^\d{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 53,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘]*$/u,
            errorMessage: "REQUIRED",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 30,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 30,
            errorMessage: "ADDRESS_SIZE",
          },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          pattern: {
            value: /^[a-zA-Z\s\-‘.@,/'#()\d]+$/u,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: () => ["WOLOLO", "HOHOHO"],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^\d{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
    },
  },
  footerLinks: ["PRIVACY", "COOKIE_POLICY", "RULES_OF_CONDUCT", "COPYRIGHT"],
  footerLogos: [],
  showHmpBenefits: true,
  showHmpPriceContent: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
};
