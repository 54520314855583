import * as yup from "yup";
import { AnyObject, Flags, Maybe, Schema } from "yup";
import {
  filterForbiddenWordValidation,
  matchesDynamicPattern,
  maxDateValidation,
  minDateValidation,
  patternValidation,
} from "./customValidations";
import {
  checkHasNoOnlySpecialSigns,
  postalCodeValidation,
} from "../personal-info/personalDetails/validations/personalDetailsValidations";
import {
  attachmentExtensionValidation,
  attachmentSizeValidation,
} from "../personal-info/attachments/validations/attachmentValidations";
import { Dayjs } from "dayjs";

yup.addMethod(
  yup.string,
  "filterForbiddenWords",
  filterForbiddenWordValidation
);
yup.addMethod(yup.string, "matchesPattern", patternValidation);
yup.addMethod(yup.string, "matchesDependencyPattern", matchesDynamicPattern);
yup.addMethod(yup.string, "matchesPostalCodeRegex", postalCodeValidation);
yup.addMethod(yup.string, "hasNoOnlySpecialSigns", checkHasNoOnlySpecialSigns);
yup.addMethod(yup.string, "isBeforeDate", maxDateValidation);
yup.addMethod(yup.string, "isAfterDate", minDateValidation);
yup.addMethod(
  yup.mixed,
  "matchesAttachmentExtensionRegex",
  attachmentExtensionValidation
);
yup.addMethod(yup.mixed, "hasCorrectSize", attachmentSizeValidation);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = "",
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    forbiddenWords(
      country: string,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    filterForbiddenWords(
      forbiddenWords: string[],
      errorMessage: string
    ): StringSchema<TType, TContext>;
    matchesPattern(
      pattern: RegExp,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    matchesDependencyPattern(
      dependencyFieldName: string,
      getPattern: (value: string) => RegExp | null,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    hasCorrectDomain(errorMessage: string): StringSchema<TType, TContext>;
    matchesPostalCodeRegex(country: string): StringSchema<TType, TContext>;
    hasNoOnlySpecialSigns(errorMessage: string): StringSchema<TType, TContext>;
    isBeforeDate(
      maxDate: Dayjs,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    isAfterDate(
      minDate: Dayjs,
      errorMessage: string
    ): StringSchema<TType, TContext>;
  }

  interface MixedSchema<TType, TContext, TDefault, TFlags>
    extends Schema<TType, TContext, TDefault, TFlags> {
    matchesAttachmentExtensionRegex(
      acceptedExtensions?: string[],
      errorMessage?: string
    ): MixedSchema<TType, TContext>;
    hasCorrectSize(
      maxSize?: number,
      errorMessage?: string
    ): MixedSchema<TType, TContext>;
  }
}

export default yup;
