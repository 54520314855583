import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "@tanstack/react-query";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../../components/common/Footer";
import globalStyles from "../../globalStyles.module.scss";
import styles from "./LandingPage.module.scss";
import { StartRegistration } from "../../features/initialRegistration/StartRegistration";
import { useInitialRegistrationData } from "../../features/initialRegistration/queries/useInitialRegistrationData";
import { BreadcrumbStepper } from "../../features/breadcrumb/BreadcrumbStepper";
import { useConfigurationQuery } from "../../features/configuration/useConfigurationQuery";
import { UNEXPECTED_EXCEPTION } from "../../api/shared";
import { Spinner } from "../../components/common/Spinner";
import { Error } from "../../components/Error/Error";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useAdobeTagPageLoadEvent } from "../../config/useAdobeTagPageLoadEvent";
import { displayCookieBanner } from "../../features/cookies/displayCookieBanner";
import { useShallow } from "zustand/react/shallow";
import { useTranslationState } from "./useTranslationState";
import { logger } from "../../utils/logger";

const LandingPage = () => {
  const { t } = useTranslation();
  const country = useProfileStore(useShallow((state) => state.country));
  const { isTranslationLoading } = useTranslationState();
  useAdobeTagPageLoadEvent();

  const { isError: isInitialRegistrationDataQueryError } =
    useInitialRegistrationData();

  const { isError: isConfigQueryError, error: configQueryError } =
    useConfigurationQuery();

  const isFetching = Boolean(
    useIsFetching({
      queryKey: ["configuration", "initialRegistration"],
    })
  );

  useEffect(() => {
    displayCookieBanner(country);
  }, [country]);

  useEffect(() => {
    if (isConfigQueryError) {
      logger.error("Failed to fetch configuration data", configQueryError);
    }
  }, [isConfigQueryError]);

  const isAuthenticated = useAuthenticationStore(
    useShallow((state) => state.isAuthenticated)
  );

  if (!isAuthenticated() || isTranslationLoading) {
    return <Spinner isShowing />;
  }

  return (
    <div className={globalStyles.moduleWrapper}>
      <Spinner isShowing={isFetching} />
      <Logo />
      <BreadcrumbStepper activeStep={1} />
      <h1 className={styles.onlineProfileHeader} data-testid="page-header">
        {t("LANDING_PAGE_HEADER")}
      </h1>
      <section className="white-container">
        {!isFetching && <StartRegistration />}
        <Error
          message={t(UNEXPECTED_EXCEPTION)}
          showOn={isInitialRegistrationDataQueryError || isConfigQueryError}
        />
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
