import { create } from "zustand";
import {
  devtools,
  persist,
  StorageValue,
  subscribeWithSelector,
} from "zustand/middleware";
import { Pickup, PickupResponse } from "../models/pickup";
import { Memberpack } from "../../models/Memberpack";
import { PickupDetails } from "../../../../types/pickupDetails";
import { isCountryWithoutPrePricedMemberpack } from "../../../../utils/isCountryWithoutPrePricedMemberpack";

interface PickupState {
  pickups: Map<string, Pickup>;
  chosenPickupLocationId?: string;
  chosenMemberpack: Memberpack;
  pickupDetails: PickupDetails;
  availableMemberpacks: Memberpack[];
}

interface PickupActions {
  setPickups: (pickups: PickupResponse[]) => void;
  selectLocationId: (locationId: string) => void;
  setPickupDetails: (pickupDetails: PickupDetails) => void;
  setAvailableMemberpacks: (memberpacks: Memberpack[]) => void;
  reset: () => void;
}

const mapToPickup = (pickupResponse: PickupResponse): Pickup => {
  return {
    memberpack: {
      pricedMemberpackId: pickupResponse.pricedMemberpackId,
      isoLocale: pickupResponse.isoLocale,
      sku: pickupResponse.sku,
      description: pickupResponse.description,
      currencyCode: pickupResponse.currencyCode,
      currencySymbol: pickupResponse.currencySymbol,
      price: pickupResponse.price,
    },
    pickupLocation: pickupResponse.pickupLocation,
  };
};

export const usePickupStore = create<PickupState & PickupActions>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          pickups: new Map<string, Pickup>(),
          chosenPickupLocationId: "",
          chosenMemberpack: <Memberpack>{},
          pickupDetails: <PickupDetails>{},
          availableMemberpacks: [],
          setPickups: (pickups: PickupResponse[]) => {
            const pickupMap = new Map<string, Pickup>();
            pickups.forEach((pickup) => {
              pickupMap.set(
                pickup.pickupLocation.pickupLocationId,
                mapToPickup(pickup)
              );
            });
            set({ pickups: pickupMap });
            set({ chosenMemberpack: pickups[0] });
          },
          setAvailableMemberpacks: (memberpacks: Memberpack[]) => {
            if (memberpacks) {
              set({ availableMemberpacks: memberpacks });
              set({ chosenMemberpack: memberpacks[0] });
            }
          },
          selectLocationId: (locationId: string) => {
            set({ chosenPickupLocationId: locationId });
            if (!isCountryWithoutPrePricedMemberpack()) {
              set({
                chosenMemberpack: get().pickups.get(locationId)?.memberpack,
              });
            }
          },
          setPickupDetails: (pickupDetails: PickupDetails) => {
            set({ pickupDetails });
          },
          reset: () => {
            set({ pickups: new Map<string, Pickup>() });
            set({ chosenPickupLocationId: "" });
            set({ chosenMemberpack: <Memberpack>{} });
            set({ pickupDetails: <PickupDetails>{} });
            set({ availableMemberpacks: [] });
          },
        }),
        {
          name: "pickup-storage",
          storage: {
            getItem: (name) => {
              const str = sessionStorage.getItem(name);
              if (!str) return null;
              const { state } = JSON.parse(str) as StorageValue<PickupState>;
              return {
                state: {
                  ...state,
                  pickups: new Map(state.pickups),
                },
              };
            },
            setItem: (name, newValue: StorageValue<PickupState>) => {
              const str = JSON.stringify({
                state: {
                  ...newValue.state,
                  pickups: Array.from(newValue.state.pickups.entries()),
                },
              });
              sessionStorage.setItem(name, str);
            },
            removeItem: (name) => sessionStorage.removeItem(name),
          },
        }
      )
    )
  )
);
