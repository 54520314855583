import * as Sentry from "@sentry/browser";
import { cookieBannerWasAlreadyShownOnSSO } from "../../config/cookies";
import { isAdobeTagEnabled } from "../../config/adobeTag";

export const displayCookieBanner = (locale: string) => {
  if (!locale || !isAdobeTagEnabled() || cookieBannerWasAlreadyShownOnSSO()) {
    return;
  }
  try {
    if (localStorage) {
      localStorage.setItem("oa-locale", locale);
    }
    const lowerCaseLocale = locale.toLowerCase();
    console.log("Displaying cookie banner for locale: ", lowerCaseLocale);
    const cookieEvent = new CustomEvent("ShowCookieBanner", {
      detail: { locale: lowerCaseLocale },
    });
    console.log("Dispatching cookie event: ", cookieEvent);
    document.dispatchEvent(cookieEvent);
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    Sentry.captureMessage(
      `Error while displaying cookie banner: ${errorMessage}`,
      {
        level: "warning",
      }
    );
  }
};
