export type Logo = {
  alt: string;
  src: string;
  key?: string;
  urlLink?: string;
};

export const VisaLogo = {
  src: "logos/Visa.png",
  alt: "Visa logo",
} as const;

export const MastercardLogo = {
  src: "logos/Mastercard.png",
  alt: "Mastercard logo",
} as const;

export const FpxLogo = {
  src: "logos/fpx.png",
  alt: "FPX logo",
} as const;

export const PayPalLogo = {
  src: "logos/PayPal.png",
  alt: "PayPal logo",
} as const;

export const SofortLogo = {
  src: "logos/sofort.png",
  alt: "Sofort logo",
} as const;

export const DsaLogo = {
  src: "logos/dsa.png",
  alt: "dsa logo",
} as const;

export const MaestroLogo = {
  src: "logos/Maestro.png",
  alt: "Maestro logo",
} as const;

export const PssbLogo = {
  src: "logos/pssb.png",
  alt: "pssb logo",
  urlLink: "https://pssb.pl",
} as const;

export const WfdsaLogo = {
  src: "logos/wfdsa.png",
  alt: "WFDSA logo",
  urlLink: "https://www.wfdsa.org/",
} as const;

export const AfepadiLogo = {
  src: "logos/afepadi.png",
  alt: "Afepadi logo",
  urlLink: "https://www.afepadi.org/",
} as const;

export const ArbitrajeLogo = {
  src: "logos/arbitraje.png",
  alt: "Arbitraje logo",
  urlLink:
    "https://www.comunidad.madrid/servicios/consumo/es-funciona-arbitraje-consumo",
} as const;

export const AvdLogo = {
  src: "logos/avd.png",
  alt: "AVD logo",
  urlLink: "https://www.avd.es/",
} as const;

export const FoodSuplementsEuropeLogo = {
  src: "logos/foodsuplementseurope.png",
  alt: "Food Suplements Europe logo",
} as const;

export const IadsaLogo = {
  src: "logos/iadsa.png",
  alt: "IADSA logo",
  urlLink: "https://www.iadsa.org/",
} as const;

export const DsaMyLogo = {
  src: "logos/dsamy.png",
  alt: "dsa malaysia logo",
} as const;

export const vdvLogo = {
  src: "logos/vdv.png",
  alt: "vdv logo",
} as const;

export const ApliLogo = {
  src: "logos/apli.png",
  alt: "apli logo",
} as const;

export const DaThongBaoLogo = {
  src: "logos/dathongbao.png",
  alt: "ĐÃ THÔNG BÁO BỘ CÔNG THƯƠNG",
} as const;
