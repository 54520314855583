import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useProfileStore } from "../../../features/initialRegistration/profileStore";
import { useConfigurationStore } from "../../../features/configuration/configurationStore";
import styles from "./Footer.module.scss";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../../config/countries/countryUtil";
import { Fragment } from "react";

type FooterProps = {
  hideVersion?: boolean;
};

export const Footer = ({ hideVersion }: FooterProps) => {
  const { t } = useTranslation();
  const version = useConfigurationStore(useShallow((state) => state.version));

  const country = useProfileStore(useShallow((state) => state.country));
  const countryConfig = getCountryConfig(country);
  const footerLogos = countryConfig?.footerLogos;
  const footerLinks = countryConfig?.footerLinks || [
    "TERMS",
    "PRIVACY",
    "RULES_OF_CONDUCT",
    "COPYRIGHT",
  ];

  return (
    <div className={styles.footer} data-testid="footer">
      {footerLinks.map((footerLink) => (
        <Fragment key={footerLink}>
          {footerLink === "TERMS" && (
            <a target="_blank" rel="noopener noreferrer" href={t("TERMS_LINK")}>
              {t("TERMS")}
            </a>
          )}
          {footerLink === "PRIVACY" && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("PRIVACY_LINK")}
            >
              {t("PRIVACY")}
            </a>
          )}
          {footerLink === "COOKIE_POLICY" && (
            <Link to="/cookiepolicy" target="_blank">
              {t("COOKIE_POLICY")}
            </Link>
          )}
          {footerLink === "RULES_OF_CONDUCT" && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("RULES_OF_CONDUCT_LINK")}
            >
              {t("RULES_OF_CONDUCT")}
            </a>
          )}
        </Fragment>
      ))}
      {footerLogos && footerLogos.length > 0 && (
        <div className={styles.footerLogosWrapper}>
          {footerLogos.map((logo) =>
            logo.urlLink ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={logo.urlLink}
                key={logo.alt}
              >
                <img key={logo.src} alt={logo.alt} src={logo.src} />
              </a>
            ) : (
              <img key={logo.src} alt={logo.alt} src={logo.src} />
            )
          )}
        </div>
      )}
      {footerLinks.includes("COPYRIGHT") ? (
        <span>{t("COPYRIGHT_NOTICE")}</span>
      ) : null}
      {hideVersion ? null : (
        <span className={styles.version} data-testid="version">
          {version}
        </span>
      )}
    </div>
  );
};
