import yup from "../../yup/yupInterface";
import {
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
  LATIN_LETTERS_REGEX,
} from "../../../config/countries/defaults";
import { useProfileStore } from "../profileStore";

const GREECE_CHARACTERS_REGEX = /^[\u0370-\u03FF\u1F00-\u1FFF ]+$/u;
const GREECE_LATIN_REGEX = new RegExp(
  GREECE_CHARACTERS_REGEX.source + "|" + LATIN_LETTERS_REGEX.source
);
const VIETNAM_CHARACTERS_REGEX =
  /^[a-zA-Zăắằẳẵặâấầẩẫậêếềểễệôốồổỗộơớờởỡợưứừửữự]+$/;

export const SponsorshipSchema = () => {
  const country = useProfileStore.getState().country;

  switch (country) {
    case "el-GR":
      return greeceSponsorshipSchema;
    case "en-MY":
    case "zh-MY":
    case "ms-MY":
      return malaysiaSponsorshipSchema;
    case "vi-VN": {
      return vietnamSponsorshipSchema;
    }
    default:
      return defaultSponsorshipSchema;
  }
};

const defaultSponsorshipSchema = yup.object({
  sponsorId: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(7, "INCORRECT_SPONSOR_ID")
    .matchesPattern(
      DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      "SPONSOR_ID_INVALID_CHARACTERS"
    ),
  sponsorNameFirstLetters: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(1, "REQUIRED"),
});

const malaysiaSponsorshipSchema = yup.object({
  sponsorId: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(7, "INCORRECT_SPONSOR_ID")
    .matchesPattern(
      DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      "SPONSOR_ID_INVALID_CHARACTERS"
    ),
  sponsorNameFirstLetters: yup
    .string()
    .trim()
    .required("REQUIRED")
    .matchesPattern(LATIN_LETTERS_REGEX, "SPONSOR_ID_INVALID_CHARACTERS"),
});

const greeceSponsorshipSchema = yup.object({
  sponsorId: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(7, "INCORRECT_SPONSOR_ID")
    .matchesPattern(
      DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      "SPONSOR_ID_INVALID_CHARACTERS"
    ),
  sponsorNameFirstLetters: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(1, "REQUIRED")
    .matchesPattern(GREECE_LATIN_REGEX, "SPONSOR_ID_INVALID_CHARACTERS"),
});

const vietnamSponsorshipSchema = yup.object({
  sponsorId: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(8, "INCORRECT_SPONSOR_ID")
    .max(10, "INCORRECT_SPONSOR_ID")
    .matchesPattern(
      DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      "SPONSOR_ID_INVALID_CHARACTERS"
    ),
  sponsorNameFirstLetters: yup
    .string()
    .trim()
    .required("REQUIRED")
    .min(1, "REQUIRED")
    .matchesPattern(VIETNAM_CHARACTERS_REGEX, "SPONSOR_ID_INVALID_CHARACTERS"),
});
