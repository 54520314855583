import { MixedSchema } from "yup";
import { DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS } from "../../../../config/constants";

const MAX_FILE_SIZE_IN_BYTES = 6000000;
export const UPLOAD_FILE_INVALID_EXTENSION = "UPLOAD_FILE_INVALID_EXTENSION";
export const PROFILE_PHOTO_INVALID_EXTENSION =
  "PROFILE_PHOTO_INVALID_EXTENSION";
export const UPLOAD_FILE_SIZE_EXCEEDED = "UPLOAD_FILE_SIZE_EXCEEDED";

export function attachmentExtensionValidation(
  this: MixedSchema,
  acceptedExtensions?: string[],
  errorMessage?: string
) {
  return this.test("attachment-extension", function validate(value) {
    const { path, createError } = this;
    const file = value as File;

    if (
      file?.name &&
      !isExtensionValid(
        file.name,
        acceptedExtensions || DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS
      )
    ) {
      {
        return createError({
          path,
          message: errorMessage ?? UPLOAD_FILE_INVALID_EXTENSION,
        });
      }
    }
    return true;
  });
}

export function attachmentSizeValidation(
  this: MixedSchema,
  maxSize?: number,
  errorMessage?: string
) {
  return this.test("attachment-size", function validate(value) {
    const { path, createError } = this;

    const file = value as File;
    if (file && file?.size > (maxSize || MAX_FILE_SIZE_IN_BYTES)) {
      return createError({
        path,
        message: errorMessage || UPLOAD_FILE_SIZE_EXCEEDED,
      });
    }
    return true;
  });
}

function isExtensionValid(
  filename: string,
  validExtensions: string[]
): boolean {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return false;
  }
  const fileExtension = filename.substring(lastDotIndex).toLowerCase();
  return validExtensions
    .map((ext) => ext.toLowerCase())
    .includes(fileExtension);
}
