import { FormValidation } from "../next-gen-config-wip";

export const DEFAULT_ADDRESS_LINE_PATTERN = RegExp(
  /^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/u,
  "u"
);
export const DIGITS_AND_OR_LATIN_LETTERS_REGEX = /^[a-zA-Z0-9]+$/;
export const LATIN_LETTERS_REGEX = /^[a-zA-Z\s]*$/;
export const defaultCountryConfigFieldLengths = {
  address: 60,
  city: 60,
  firstName: 20,
  lastName: 30,
  middleInitial: 20,
  mothersMaidenName: 30,
  postalCode: 4,
  province: 30,
  registrationNumber: 15,
  spouseFirstName: 30,
  spouseMiddleName: 20,
  spouseLastName: 20,
  careOfName: 51,
} as const;

export const defaultShippingAddressCareOfNameValidation: FormValidation = {
  required: { value: true, errorMessage: "REQUIRED" },
  maxLength: {
    value: defaultCountryConfigFieldLengths.careOfName,
    errorMessage: "HMP_CARE_OF_NAME_SIZE",
  },
  pattern: {
    value: /.*\S.*/,
    errorMessage: "REQUIRED",
  },
  hasNoOnlySpecialSigns: {
    value: true,
    errorMessage: "REQUIRED",
  },
  trim: true,
} as const;

export const commonForbiddenWords = [
  "po123",
  "po 123",
  "p o123",
  "p o 123",
  "p.o123",
  "p.o 123",
  "postbox",
  "post box",
  "postbox123",
  "postboks 123",
  "post boks123",
  "box",
  "boxs123",
  "boxs 123",
  "pobox",
  "po box",
  "p.o box",
  "p.o. box",
  "p.o boX",
  "po boX",
  "po boX1",
  "po-box",
  "p o–box",
  "postfach",
  "c/o",
  "apartado de correos",
  "apdo. correos",
  "ap. correos",
  "casella postale",
  "c.p.",
  "outbox",
  "out box",
  "obox",
  "郵政信箱",
  "邮政信箱",
  "信箱",
  "postbus",
  "p.o.box",
  "p o box",
  "po–box",
  "p o – box",
  "Τ.Θ",
  "ΤΘ",
  "Ταχυδρομική Θυρίδα",
];

export const defaultForbiddenWordsValidation = {
  value: commonForbiddenWords,
  errorMessage: "PO_BOX",
};
