import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { useProfileStore } from "../../initialRegistration/profileStore";
import { getCountryConfig } from "../../../config/countries/countryUtil";

export type MarketingState = {
  beenMemberBefore: boolean | null;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted?: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents?: boolean[];
};

export type MarketingPayload = {
  beenMemberBefore: boolean;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted?: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents?: boolean[];
};

export type MarketingFormState = {
  beenMemberBefore: boolean | null;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted?: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  [key: `socialMediaConsent${number}`]: boolean;
};

interface MarketingAction {
  setMarketing: (
    personalInfoValues: WithTypeContaining<MarketingFormState>
  ) => void;
  reset: () => void;
}

const initialState: MarketingState = {
  beenMemberBefore: null,
  marketingConsentAccepted: false,
  dataPrivacyAcknowledgementAccepted: false,
  documentationConfirmed: false,
  prospectOwnsSignificantSocialMediaAccount: null,
  socialMediaConsents: [],
};

export const useMarketingStore = create<MarketingState & MarketingAction>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setMarketing: (personalInfoValues) => {
          set(createMarketingFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "marketing-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

const isDataPrivacyAcknowledgementAccepted = (country: string) => {
  const isDataPrivacyAcknowledgement = Boolean(
    getCountryConfig(country)?.isDataPrivacyAcknowledgement
  );
  return isDataPrivacyAcknowledgement
    ? {
        dataPrivacyAcknowledgementAccepted:
          useMarketingStore.getState().dataPrivacyAcknowledgementAccepted,
      }
    : {};
};

const createMarketingFromForm = ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  ...rest
}: WithTypeContaining<MarketingFormState>): MarketingState => {
  const socialMediaConsents = Object.entries(rest)
    .filter(([key]) => {
      return Boolean(key.match(/^socialMediaConsent\d+$/));
    })
    .map(([, value]) => Boolean(value));

  return {
    beenMemberBefore: beenMemberBefore ?? false,
    marketingConsentAccepted,
    dataPrivacyAcknowledgementAccepted,
    documentationConfirmed,
    prospectOwnsSignificantSocialMediaAccount,
    socialMediaConsents,
  };
};

export const marketingFromStore = () => {
  const socialMediaConsents = useMarketingStore
    .getState()
    .socialMediaConsents?.reduce(
      (consents, consent, index) => ({
        ...consents,
        [`socialMediaConsent${index}`]: Boolean(consent),
      }),
      {}
    );

  return {
    beenMemberBefore: useMarketingStore.getState().beenMemberBefore,
    marketingConsentAccepted:
      useMarketingStore.getState().marketingConsentAccepted,
    documentationConfirmed: useMarketingStore.getState().documentationConfirmed,
    prospectOwnsSignificantSocialMediaAccount:
      useMarketingStore.getState().prospectOwnsSignificantSocialMediaAccount,
    ...socialMediaConsents,
    ...isDataPrivacyAcknowledgementAccepted(useProfileStore.getState().country),
  };
};
