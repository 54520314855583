import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

export const Bulgaria: CountryFormConfiguration = {
  id: "bg-BG",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "INITIALS",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "MIDDLE_INITIAL_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.middleInitial,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",

        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
  },
  footerLinks: [
    "TERMS",
    "PRIVACY",
    "COOKIE_POLICY",
    "RULES_OF_CONDUCT",
    "COPYRIGHT",
  ],
  footerLogos: [],
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: 30,
  postalCodeLength: defaultCountryConfigFieldLengths.postalCode,
  postalCodeMask: false,
  postalCodePattern: /^\d{4}$/,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: true,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDeliveryDisclaimer: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
} as const;
