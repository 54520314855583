import { useEffect } from "react";
import { useAuthenticationStore } from "../../authentication/authenticationStore";
import { resetAllStores } from "../resetAllStores";
import { useInitialRegistrationMutation } from "../mutations/useInitialRegistrationMutation";
import * as Sentry from "@sentry/browser";
import { parseJwt } from "../../../utils/jwt";
import { useProfileStore } from "../profileStore";
import { useShallow } from "zustand/react/shallow";
import { getOaTokenCookie, removeTokenCookie } from "../../../config/cookies";

export const useInitialRegistrationData = () => {
  const initialRegistrationMutation = useInitialRegistrationMutation();
  const [setToken, isAuthenticated] = useAuthenticationStore(
    useShallow((state) => [state.setToken, state.isAuthenticated])
  );
  const country = useProfileStore(useShallow((state) => state.country));

  const currentUserRefreshesPage = (tokenCookie: string | undefined) =>
    !tokenCookie && isAuthenticated();

  useEffect(() => {
    const oaTokenCookie = getOaTokenCookie();
    if (currentUserRefreshesPage(oaTokenCookie)) {
      return;
    }

    if (!oaTokenCookie) {
      window.location.href = import.meta.env.VITE_SSO_COUNTRY_SELECTION_LINK;
    } else {
      resetAllStores();
      setToken(oaTokenCookie);
      if (country) {
        Sentry.setTag("country", country);
      }
      Sentry.setTag("registrationId", parseJwt(oaTokenCookie).registrationId);
      initialRegistrationMutation.mutate();
      removeTokenCookie();
    }
  }, []);

  return initialRegistrationMutation;
};
