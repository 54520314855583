import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { DeliveryMethod } from "../deliveryMethods";

type DeliveryState = {
  herbalifeOrderNumber: string;
  chosenDeliveryMethod: DeliveryMethod | null;
};

type DeliveryAction = {
  setHerbalifeOrderNumber: (orderNumber: string) => void;
  setDeliveryMethod: (deliveryMethod: DeliveryMethod | null) => void;
  reset: () => void;
};

export const useDeliveryStore = create<DeliveryState & DeliveryAction>()(
  devtools(
    persist(
      (set) => ({
        herbalifeOrderNumber: "",
        chosenDeliveryMethod: null,
        setHerbalifeOrderNumber: (herbalifeOrderNumber: string) =>
          set({ herbalifeOrderNumber }),
        setDeliveryMethod: (chosenDeliveryMethod) =>
          set({ chosenDeliveryMethod }),
        reset: () =>
          set({ herbalifeOrderNumber: "", chosenDeliveryMethod: null }),
      }),
      {
        name: "delivery-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
