import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  DEFAULT_NAMES_PATTERN,
} from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

export const Namibia: CountryFormConfiguration = {
  id: "en-NA",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "INITIALS",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: false },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "MIDDLE_INITIAL_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.middleInitial,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: {
            value: /^[a-zA-Z0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: false, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 0,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: {
            value: /^[a-zA-Z0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
        },
      },
    },
  },
  footerLinks: ["TERMS", "PRIVACY", "RULES_OF_CONDUCT", "COPYRIGHT"],
  footerLogos: [],
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeLength: defaultCountryConfigFieldLengths.postalCode,
  postalCodeMask: false,
  postalCodePattern: /^[a-zA-Z0-9]{4}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDocumentationBox: false,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
} as const;
