import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { SponsorInformationForm } from "../../features/initialRegistration/sponsorship/SponsorInformationForm";
import { useConfirmSponsorshipMutation } from "../../features/initialRegistration/sponsorship/mutations/confirmSponsorshipMutation";
import { ResponseError } from "../../features/common/api/ResponseError";
import { UNEXPECTED_EXCEPTION } from "../../api/shared";
import { SelectedSponsor } from "../../features/initialRegistration/sponsorship/SelectedSponsor";
import { useSponsorForm } from "../../features/initialRegistration/forms/useSponsorForm";
import { useSponsorshipStore } from "../../features/initialRegistration/sponsorship/sponsorshipStore";
import { useShallow } from "zustand/react/shallow";
import { useEventStore } from "../../features/events/eventStore";
import { RegistrationEvent } from "../../features/events/models/RegistrationEvent";

export const SponsorInformation = () => {
  const confirmSponsorshipMutation = useConfirmSponsorshipMutation();
  const [sponsorshipServerError, setSponsorshipServerError] = useState("");
  const sponsorForm = useSponsorForm();
  const publish = useEventStore(useShallow((state) => state.publish));
  const [clearConfirmation, setSponsorFormData, confirmedSponsorId] =
    useSponsorshipStore(
      useShallow((state) => [
        state.clearConfirmation,
        state.setSponsorFormData,
        state.confirmedSponsorId,
      ])
    );

  const isSponsorConfirmed = () => confirmedSponsorId.length > 0;

  const editSponsor = () => {
    clearConfirmation();
  };

  const onSubmit = () => {
    confirmSponsorshipMutation.mutate(
      {
        introducingMemberId: sponsorForm.getValues("sponsorId"),
        firstThreeLettersOfLastName: sponsorForm.getValues(
          "sponsorNameFirstLetters"
        ),
      },
      {
        onError: (error: ResponseError) => {
          if (error.response?.status === 404) {
            setSponsorshipServerError("NOT_FOUND_SPONSOR");
          } else {
            setSponsorshipServerError(UNEXPECTED_EXCEPTION);
          }
        },
        onSuccess: () => {
          setSponsorFormData(
            sponsorForm.getValues("sponsorId"),
            sponsorForm.getValues("sponsorNameFirstLetters")
          );
          publish(RegistrationEvent.SponsorshipConfirmed);
        },
      }
    );
  };

  return isSponsorConfirmed() ? (
    <SelectedSponsor editSponsor={editSponsor} />
  ) : (
    <FormProvider {...sponsorForm}>
      <SponsorInformationForm
        onSubmit={onSubmit}
        isCheckPending={confirmSponsorshipMutation.isPending}
        isErrorMessageVisible={confirmSponsorshipMutation.isError}
        errorMessage={sponsorshipServerError}
      />
    </FormProvider>
  );
};
