import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { get } from "react-hook-form";
import {
  IDENTITY_CONFIRMATION_FAILED_ERROR_CODE,
  IDENTITY_CONFIRMATION_NOT_MATCH_ERROR_CODE,
} from "../../../types/errorCodes";
import { WithTypeContaining } from "../../../types/utilityTypes";

export enum IdentityConfirmationState {
  NOT_MATCH = "NOT_MATCH",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN",
}

export const ErrorCodeToIdentityConfirmationState: Record<
  string,
  IdentityConfirmationState
> = {
  [IDENTITY_CONFIRMATION_NOT_MATCH_ERROR_CODE]:
    IdentityConfirmationState.NOT_MATCH,
  [IDENTITY_CONFIRMATION_FAILED_ERROR_CODE]: IdentityConfirmationState.FAILED,
};

export interface RegistrationNumber {
  documentType: string;
  documentValue: string;
  issueDate: string;
  expiryDate: string;
  index: number;
}

interface TinsState {
  isResident: boolean | null;
  registrationNumbers: RegistrationNumber[];
  identityConfirmationState: IdentityConfirmationState;
}

interface TinsAction {
  setTins: (personalInfoValues: WithTypeContaining<TinValues>) => void;
  setResident: (isResident: boolean) => void;
  setIdentityConfirmationState: (
    identityConfirmationState: IdentityConfirmationState
  ) => void;
  reset: () => void;
}

const initialState: TinsState = {
  isResident: null,
  registrationNumbers: [],
  identityConfirmationState: IdentityConfirmationState.UNKNOWN,
};

export type TinValues = {
  isResident: boolean | null;
  [
    key:
      | `registrationNumber${number}`
      | `registrationNumber${number}DocumentType`
      | `registrationNumber${number}IssueDate`
      | `registrationNumber${number}ExpiryDate`
      | `registrationNumberNonResident${number}`
      | `registrationNumberNonResident${number}IssueDate`
      | `registrationNumberNonResident${number}ExpiryDate`
  ]: string;
};

export const useTinsStore = create<TinsState & TinsAction>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setTins: (personalInfoValues) => {
          set(createTinsFromForm(personalInfoValues));
        },
        setResident: (isResident) => {
          set({ isResident });
        },
        setIdentityConfirmationState: (identityConfirmationState) => {
          set({ identityConfirmationState });
        },
        reset: () => set(initialState),
      }),
      {
        name: "tins-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const createTinsFromForm = (
  personalInfoValues: WithTypeContaining<TinValues>
) => {
  return {
    isResident: !!get(personalInfoValues, "isResident"),
    registrationNumbers: Object.entries<TinValues>(personalInfoValues)
      .filter(
        ([key]) =>
          /^registrationNumber\d+$/.test(key) ||
          /^registrationNumberNonResident\d+$/.test(key)
      )
      .map(([name]) => {
        const prefix = `registrationNumber${get(personalInfoValues, "isResident") ? "" : "NonResident"}`;
        const index = Number(name.slice(-1));
        return {
          index: Number(name.slice(-1)),
          documentType: get(
            personalInfoValues,
            `${prefix}${index}DocumentType`
          ) as string,
          documentValue: get(personalInfoValues, `${prefix}${index}`) as string,
          issueDate: get(
            personalInfoValues,
            `${prefix}${index}IssueDate`
          ) as string,
          expiryDate: get(
            personalInfoValues,
            `${prefix}${index}ExpiryDate`
          ) as string,
        };
      }),
  };
};
