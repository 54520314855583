import * as React from "react";
import { ChangeEvent, ForwardedRef, forwardRef, HTMLAttributes } from "react";
import TextField from "@mui/material/TextField";
import classNames from "classnames";

import { ExternalLink } from "../ExternalLink";
import styles from "./styles.module.scss";
import { FormFieldError } from "../../../utils/formsUtils";
import { customSanitizeValueOnChange } from "../../../utils/replacement";
import { IMaskInput } from "react-imask";
import { SanitizeMode, Sanitizer } from "../../../config/next-gen-config-wip";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FormFieldError | null;
  format?: string | string[] | null;
  linkName?: string;
  label?: string;
  noLabelMargin?: boolean;
  linkUrl?: string;
  required?: boolean;
  onLinkClick?: () => void;
  shrinkedLabel?: boolean;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  pattern?: string;
  sanitize?: Sanitizer[];
}

const isTextFormat = (format: string[] | string | undefined | null) => {
  if (!format) {
    return true;
  }

  if (Array.isArray(format)) {
    return format.some((singleMask) => singleMask.includes("a"));
  }

  return format.includes("a");
};

const PatternInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    // @ts-expect-error - IMaskInput does not have all the props of an input element
    <IMaskInput
      {...props}
      mask={
        Array.isArray(props.format)
          ? props.format.map((format) => ({ mask: format }))
          : String(props.format)
      }
      type="text"
      value={props.value as string}
      defaultValue={props.defaultValue as string}
      inputRef={ref}
      inputMode={isTextFormat(props?.format) ? "text" : "numeric"}
      onAccept={(value: string) =>
        props.onChange?.({
          target: { value },
        } as React.ChangeEvent<HTMLInputElement>)
      }
    />
  );
});

export const Input = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      error,
      format,
      label,
      linkName,
      linkUrl,
      noLabelMargin,
      required,
      onLinkClick,
      shrinkedLabel,
      maxLength,
      sanitize,
      onChange,
      ...nativeInputProps
    } = props;

    const inputProps = () => {
      if (format) {
        return {
          inputComponent: PatternInput,
          inputProps: {
            format: props.format,
            id: props.id,
            "data-testid": props.id,
            name: props.name,
            onBlur: props.onBlur,
            onChange: props.onChange,
            value: props.value as string,
            disabled: props.disabled,
            type: props.type,
          },
        };
      }
      return {
        inputProps: {
          maxLength,
          "data-testid": props.id,
          onKeyDown: props.onKeyDown,
          type: props.type,
          inputMode: props.inputMode,
          pattern: props.pattern,
        },
      };
    };

    const sanitizeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const sanitizerOnChange = sanitize?.find(
        (sanitizer) => sanitizer.mode === SanitizeMode.ON_CHANGE
      );
      if (!sanitizerOnChange) {
        return;
      }
      const { sanitizeFn } = sanitizerOnChange;

      customSanitizeValueOnChange(e, sanitizeFn);
    };

    return (
      <div data-testid="component-input" className={styles.inputContainer}>
        <div
          className={classNames(styles.labelsContainer, {
            [styles.noLabelMargin]: noLabelMargin,
          })}
        >
          {linkName && (
            <ExternalLink linkUrl={linkUrl} onClick={onLinkClick}>
              {linkName}
            </ExternalLink>
          )}
        </div>
        <TextField
          required={required}
          disabled={nativeInputProps.disabled}
          error={Boolean(error)}
          id={nativeInputProps.id}
          label={label}
          name={nativeInputProps.name}
          onBlur={nativeInputProps.onBlur}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            sanitizeOnChange(e);
            if (
              onChange &&
              (maxLength ? e.target.value.length <= maxLength : true)
            ) {
              onChange(e);
            }
          }}
          onCopy={nativeInputProps.onCopy}
          placeholder={nativeInputProps.placeholder}
          value={nativeInputProps.value || nativeInputProps.defaultValue || ""}
          inputRef={ref}
          variant="outlined"
          slotProps={{
            input: inputProps(),
            inputLabel: shrinkedLabel ? { shrink: true } : undefined,
          }}
        />
      </div>
    );
  }
);
