import createDOMPurify, { Config } from "dompurify";
import { logger } from "./logger";

type DomReturnType = {
  RETURN_DOM_FRAGMENT?: false;
  RETURN_DOM?: false;
};

export const sanitize = (
  html: string,
  config: Config & DomReturnType = {}
): string => {
  if (typeof window === "undefined") {
    logger.warn("Window is not defined, skipping sanitization");
    return html;
  }
  const DOMPurify = createDOMPurify(window);
  return DOMPurify.sanitize(html, config);
};
