import { IdentityConfirmationState } from "../../features/personal-info/tins/tinsStore";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  DEFAULT_NAMES_PATTERN,
  Gender,
} from "../constants";
import { VisibleOnRule } from "../model/VisibleOnRule";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import {
  AfepadiLogo,
  ArbitrajeLogo,
  AvdLogo,
  FoodSuplementsEuropeLogo,
  IadsaLogo,
  MastercardLogo,
  VisaLogo,
  WfdsaLogo,
} from "../../components/common/Logo/models/Logo";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

const CEUTA_AND_MELILLA_POSTAL_CODE_REGEX =
  /^(5100[1-5]|5107[0-1]|51080|5200[1-6]|5207[0-1]|52080)$/;

const SPANISH_ADDRESS_REGEX = /^[\p{Lower}\p{Upper}\s\-,./º:\d]*$/u;
const SPANISH_FOREIGNER_ID_REGEX = "^[a-zA-Z]\\d{7}[a-zA-Z]$";
const SPANISH_TAX_ID_REGEX = "^\\d{8}[a-zA-Z]$";
const SPANISH_TIN_PATTERN = new RegExp(
  `${SPANISH_FOREIGNER_ID_REGEX}|${SPANISH_TAX_ID_REGEX}`
);

export const Spain: CountryFormConfiguration = {
  id: "es-ES",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        bottomDescription: { text: "FIRST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        bottomDescription: { text: "LAST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 0,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: SPANISH_TIN_PATTERN,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          identityConfirmation: true,
          trim: true,
        },
        rule: {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState !== IdentityConfirmationState.FAILED,
          ] as VisibleOnRule<IdentityConfirmationState>,
          keepValueOnHidden: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "postalCode",
            (postalCode: string) =>
              CEUTA_AND_MELILLA_POSTAL_CODE_REGEX.test(postalCode),
          ],
        },
      },
      registrationNumber0Attachment1: {
        name: "registrationNumber0-attachment1",
        label: "UPLOAD_FILE_INFO_RESIDENT1",
        type: "file",
        index: 1,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState === IdentityConfirmationState.FAILED,
          ] as VisibleOnRule<IdentityConfirmationState>,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "radio",
        options: [
          { value: Gender.MALE, label: "MALE" },
          { value: Gender.FEMALE, label: "FEMALE" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        validationErrorStyling: ["textCentered"],
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: SPANISH_ADDRESS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      country: {
        name: "country",
        label: "COUNTRY",
        type: "text",
        defaultValue: "España",
        validation: {
          required: { value: false },
        },
        rule: {
          isDisabled: true,
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
      prospectOwnsSignificantSocialMediaAccount: {
        name: "prospectOwnsSignificantSocialMediaAccount",
        label: "SOCIAL_MEDIA_FOLLOWERS_QUESTION",
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      socialMediaConsents: {
        name: "socialMediaConsentsWrapper",
        label: "SOCIAL_MEDIA",
        type: "object",
        classNames: ["collapse"],
        rule: {
          visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
        },
        validation: {
          required: { value: false },
        },
        isCollapsable: true,
        fields: {
          customerAcknowledgementInfo: {
            name: "customerAcknowledgementInfo",
            type: "static",
            staticValue: "CUSTOMER_ACKNOWLEDGEMENT_INFO",
          },
          socialMediaConsent0: {
            name: "socialMediaConsent0",
            label: "CUSTOMER_SERVICE",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CONTACT_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent1: {
            name: "socialMediaConsent1",
            label: "CUSTOMER_LEAD",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CUSTOMER_LEAD_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent2: {
            name: "socialMediaConsent2",
            label: "SALES_AND_MARKETING_PLAN",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SALES_AND_MARKETING_PLAN_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent3: {
            name: "socialMediaConsent3",
            label: "NO_ASSOCIATION",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "NO_ASSOCIATION_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent4: {
            name: "socialMediaConsent4",
            label: "SOCIAL_MEDIA",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SOCIAL_MEDIA_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent5: {
            name: "socialMediaConsent5",
            label: "EARNINGS_CLAIMS",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "EARNINGS_CLAIMS_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaEndingNote: {
            name: "socialMediaEndingNote",
            type: "static",
            staticValue: "SOCIAL_MEDIA_ENDING_NOTE",
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: SPANISH_ADDRESS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
    },
  },
  addressLength: 50,
  addressPattern: SPANISH_ADDRESS_REGEX,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "00000",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: defaultCountryConfigFieldLengths.province,
  provinceRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "España",
  readOnlyFields: ["country"],
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLinks: [
    "TERMS",
    "PRIVACY",
    "COOKIE_POLICY",
    "RULES_OF_CONDUCT",
    "COPYRIGHT",
  ],
  footerLogos: [
    AfepadiLogo,
    ArbitrajeLogo,
    AvdLogo,
    FoodSuplementsEuropeLogo,
    IadsaLogo,
    WfdsaLogo,
    VisaLogo,
    MastercardLogo,
  ],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  showCountryInput: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["es"],
  defaultCountryCode: "+34|Spain",
} as const;
