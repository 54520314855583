import * as Sentry from "@sentry/browser";
import { parseJwt } from "./jwt";
import { useAuthenticationStore } from "../features/authentication/authenticationStore";
import { useProfileStore } from "../features/initialRegistration/profileStore";

type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const logger = {
  user: {},
  info(...data: unknown[]) {
    console.info(...data);
  },
  warn(...data: unknown[]) {
    console.warn(...data);
  },
  error(errorOrMessage: Error | string, ...data: unknown[]) {
    const { country } = useProfileStore.getState();
    const token = useAuthenticationStore.getState().getToken();
    console.error(errorOrMessage, ...data);
    const error = isErrorWithMessage(errorOrMessage)
      ? errorOrMessage
      : new Error(String(errorOrMessage));

    Sentry.setTag("registrationId", parseJwt(token).registrationId);
    if (country) {
      Sentry.setTag("country", country);
    }
    Sentry.captureException(error, {
      extra: {
        additionalContext: data ? JSON.stringify(data) : data,
      },
    });
  },
};
