import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import {
  MastercardLogo,
  VisaLogo,
} from "../../components/common/Logo/models/Logo";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

export const SwitzerlandIT: CountryFormConfiguration = {
  id: "it-CH",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "0000",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
      prospectOwnsSignificantSocialMediaAccount: {
        name: "prospectOwnsSignificantSocialMediaAccount",
        label: "SOCIAL_MEDIA_FOLLOWERS_QUESTION",
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      socialMediaConsents: {
        name: "socialMediaConsentsWrapper",
        label: "SOCIAL_MEDIA",
        type: "object",
        classNames: ["collapse"],
        rule: {
          visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
        },
        validation: {
          required: { value: false },
        },
        isCollapsable: true,
        fields: {
          customerAcknowledgementInfo: {
            name: "customerAcknowledgementInfo",
            type: "static",
            staticValue: "CUSTOMER_ACKNOWLEDGEMENT_INFO",
          },
          socialMediaConsent0: {
            name: "socialMediaConsent0",
            label: "CUSTOMER_SERVICE",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CONTACT_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent1: {
            name: "socialMediaConsent1",
            label: "CUSTOMER_LEAD",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CUSTOMER_LEAD_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent2: {
            name: "socialMediaConsent2",
            label: "SALES_AND_MARKETING_PLAN",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SALES_AND_MARKETING_PLAN_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent3: {
            name: "socialMediaConsent3",
            label: "NO_ASSOCIATION",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "NO_ASSOCIATION_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent4: {
            name: "socialMediaConsent4",
            label: "SOCIAL_MEDIA",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SOCIAL_MEDIA_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent5: {
            name: "socialMediaConsent5",
            label: "EARNINGS_CLAIMS",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "EARNINGS_CLAIMS_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaEndingNote: {
            name: "socialMediaEndingNote",
            type: "static",
            staticValue: "SOCIAL_MEDIA_ENDING_NOTE",
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "0000",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 30,
  postalCodeLength: 4,
  postalCodeMask: "0000",
  postalCodePattern: /^[0-9]{4}$/,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLinks: [
    "TERMS",
    "PRIVACY",
    "COOKIE_POLICY",
    "RULES_OF_CONDUCT",
    "COPYRIGHT",
  ],
  footerLogos: [VisaLogo, MastercardLogo],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["ch"],
  defaultCountryCode: "+41|Switzerland",
} as const;
