import { HTMLInputTypeAttribute } from "react";
import { Dayjs } from "dayjs";

import { VisibleOnRule } from "./model/VisibleOnRule";
import { AttachmentType } from "../features/personal-info/attachments/attachmentsStore";

type FormNames =
  | "register"
  | "phoneVerification"
  | "personalInfo"
  | "shippingAddress";

export type FieldNames =
  | "addressLine1"
  | "addressLine2"
  | "sponsorId"
  | "sponsorName"
  | "countryCode"
  | "country"
  | "careOfName"
  | "phoneNumber"
  | "pin"
  | "address"
  | "city"
  | "state"
  | "firstName"
  | "lastName"
  | "middleInitial"
  | "mothersMaidenName"
  | "placeOfBirth"
  | "postalCode"
  | "dateOfBirth"
  | "documentationConfirmed"
  | "province"
  | "district"
  | "subdistrict"
  | "houseNumber"
  | "spouseFirstName"
  | "spouseMiddleName"
  | "spouseLastName"
  | "documentTypes"
  | "expiryDate"
  | "issueDate"
  | `attachment${number}`
  | "residentialStatus"
  | "residentialTins"
  | "nonResidentialTins"
  | "selection"
  | "residencePermitAttachment"
  | "isPublicAdministrationEmployee"
  | "publicAdministrationAttachment"
  | "gender"
  // TODO to remove after tins migration
  | "tins"
  | "profilePhotoAttachment"
  | "ethnicity"
  | "beenMemberBefore"
  | "isResident"
  | `registrationNumber${number}`
  | `registrationNumber${number}DocumentType`
  | `registrationNumber${number}ExpiryDate`
  | `registrationNumber${number}IssueDate`
  | `registrationNumber${number}Attachment${number}`
  | `registrationNumberNonResident${number}`
  | `registrationNumberNonResident${number}ExpiryDate`
  | `registrationNumberNonResident${number}IssueDate`
  | `registrationNumberNonResident${number}Attachment${number}`
  | "marketingConsentAccepted"
  | "dataPrivacyAcknowledgementAccepted"
  | "prospectOwnsSignificantSocialMediaAccount"
  | "socialMediaConsents"
  | `socialMediaConsent${number}`;

export type FormValidation = {
  required: { value: boolean; errorMessage?: string };
  pattern?: { value: RegExp; errorMessage?: string };
  mask?: string | string[];
  maxLength?: { value: number; errorMessage: string };
  minLength?: { value: number; errorMessage: string };
  maxDate?: { value: Dayjs; errorMessage: string };
  minDate?: { value: Dayjs; errorMessage: string };
  forbiddenWords?: { value: string[]; errorMessage: string };
  trim?: boolean;
  hasNoOnlySpecialSigns?: { value: boolean; errorMessage: string };
  acceptedExtensions?: { value: string[]; errorMessage: string };
  dynamicPattern?: {
    dependency: string;
    getPattern: (dependency: string) => RegExp | null;
    errorMessage: string;
  };
  identityConfirmation?: boolean;
  maxSize?: {
    value: number;
    errorMessage?: string;
  };
};

export type FormField<T = string> = {
  name: string;
  label: string;
  type: HTMLInputTypeAttribute;
  validation: FormValidation;
  key?: string;
  index?: number;
  placeholder?: string;
  attachmentType?: AttachmentType;
  acceptedExtensions?: string[];
  defaultValue?: T;
  options?: { value: T; label: string }[];
  getOptions?: (...parameters: string[]) => Promise<T[]> | T[];
  getOptionLabel?: (option: T) => string;
  dependenciesNames?: string[];
  isCollapsable?: boolean;
  topDescription?: {
    rule?: {
      visibleOn: VisibleOnRule<string>;
    };
    text: string;
  };
  bottomDescription?: FieldDescription;
  middleDescription?: {
    rule?: {
      visibleOn: VisibleOnRule<string>;
    };
    text: string;
  };
  sanitize?: Sanitizer[];
  rule?: {
    isDisabled?: boolean;
    isHidden?: boolean;
    visibleOn?: VisibleOnRule<string> | undefined;
    keepValueOnHidden?: boolean;
    enableOn?: string[];
  };
  fields?: Partial<FormProperty>;
  classNames?: string[];
  labelExtraClassNames?: string[];
  validationErrorStyling?: string[];
  dynamicDefaultValue?: {
    dependency: string;
    getDefaultValue: (dependency: string) => T;
  };
};

export type FieldDescription = {
  rule?: {
    visibleOn: VisibleOnRule<string>;
  };
  text: string;
  individualStyling?: string[];
};

export type FormProperty =
  | {
      [fieldName in FieldNames]: FormField<string | boolean | object>;
    }
  | { [key: string]: StaticSectionProperty };

export type StaticSectionProperty = {
  name: string;
  header?: string;
  type: "static";
  staticValue: string;
};

export type FormsValidation = {
  forms: {
    [formName in FormNames]: Partial<FormProperty>;
  };
};

export enum SanitizeMode {
  ON_CHANGE = "ON_CHANGE",
  ON_SUBMIT = "ON_SUBMIT",
}

export type Sanitizer = {
  mode: SanitizeMode;
  sanitizeFn: (value: string) => string;
};
