import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { useAuthorizationStore } from "../authorization/authorizationStore";
import { RegistrationEvent } from "./models/RegistrationEvent";

type EventLog = {
  event: RegistrationEvent;
  date: Date;
};

type EventState = {
  eventLog: EventLog[];
};

type EventAction = {
  publish: (event: RegistrationEvent) => void;
};

const handleEvent = (event: RegistrationEvent) => {
  useAuthorizationStore.getState().handleEvent(event);
  if (event === RegistrationEvent.PaymentDone) {
    useEventStore.getState().publish(RegistrationEvent.RegistrationCompleted);
  }
};

export const useEventStore = create<EventState & EventAction>()(
  devtools(
    persist(
      (set, get) => ({
        eventLog: [],
        publish: (event) => {
          set({
            eventLog: [...get().eventLog, { event, date: new Date() }],
          });
          handleEvent(event);
        },
      }),
      {
        name: "event-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
