import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/browser";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { parseJwt } from "../../utils/jwt";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useShallow } from "zustand/react/shallow";

type ReactErrorBoundaryProps = {
  children?: ReactNode;
};

export const ReactErrorBoundary = (props: ReactErrorBoundaryProps) => {
  const { children } = props;
  const token = useAuthenticationStore(useShallow((state) => state.token));
  const country = useProfileStore(useShallow((state) => state.country));

  const handleHardReload = async () => {
    await fetch(window.location.pathname, {
      headers: {
        Pragma: "no-cache",
        Expires: "-1",
        "Cache-Control": "no-cache,no-store,must-revalidate",
      },
    });
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => {
        Sentry.setTag("ReactErrorBoundary", true);
        Sentry.setTag("registrationId", parseJwt(token).registrationId);
        if (country) {
          Sentry.setTag("country", country);
        }
        Sentry.captureException(error);
      }}
      onReset={handleHardReload}
    >
      {children}
    </ErrorBoundary>
  );
};
