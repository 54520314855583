import dayjs from "dayjs";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";

import { CountryFormConfiguration } from "../constants";
import { DaThongBaoLogo } from "../../components/common/Logo/models/Logo";

import { SanitizeMode } from "../next-gen-config-wip";

const VIETNAM_CHARACTERS_REGEX =
  /^[a-zA-Zăắằẳẵặâấầẩẫậêếềểễệôốồổỗộơớờởỡợưứừửữự]+$/;
const VIETNAM_NAMES_SPECIAL_CHARACTERS_REGEX = /^[\s\-‘]+$/;
const VIETNAM_RESTRICTED_CHARACTERS_REGEX = /^[()&^%$!`~_+=<>]+$/;
const VIETNAM_ID_CARD_NUMBER_REGEX = /^[0-9]{12}$/;

export const Vietnam: CountryFormConfiguration = {
  id: "vi-VN",
  forms: {
    personalInfo: {
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) =>
              value.replace(VIETNAM_RESTRICTED_CHARACTERS_REGEX, ""),
          },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: 30,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: new RegExp(
              VIETNAM_CHARACTERS_REGEX.source +
                "|" +
                VIETNAM_NAMES_SPECIAL_CHARACTERS_REGEX.source
            ),
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) =>
              value.replace(VIETNAM_RESTRICTED_CHARACTERS_REGEX, ""),
          },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: 20,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: new RegExp(
              VIETNAM_CHARACTERS_REGEX.source +
                "|" +
                VIETNAM_NAMES_SPECIAL_CHARACTERS_REGEX.source
            ),
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 12,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 12,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: VIETNAM_ID_CARD_NUMBER_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0IssueDate: {
        name: "registrationNumber0IssueDate",
        label: "ISSUE_DATE",
        type: "date",
        bottomDescription: {
          text: "REGISTRATION_NUMBER_ISSUE_DATE_DISCLAIMER",
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(1, "day"),
            errorMessage: "WRONG_ISSUE_DATE",
          },
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.AUTHORIZATION_DOCUMENT,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".pdf", ".bmp", ".jpg", ".jpeg"],
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
      registrationNumber0Attachment1: {
        name: "registrationNumber0-attachment1",
        label: "UPLOAD_FILE_INFO_RESIDENT1",
        type: "file",
        index: 1,
        attachmentType: AttachmentType.AUTHORIZATION_DOCUMENT,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".pdf", ".bmp", ".jpg", ".jpeg"],
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
    },
  },
  hideFooterTerms: true,
  hideRulesOfConduct: true,
  footerLinks: ["PRIVACY", "COOKIE_POLICY", "COPYRIGHT"],
  footerLogos: [DaThongBaoLogo],
  showHmpBenefits: true,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  showSponsorshipDisclaimer: true,
  supportedCountryCodes: ["vi"],
  defaultCountryCode: "+84|Vietnam",
} as const;
