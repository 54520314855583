import { ResponseError } from "../features/common/api/ResponseError";

const REQUEST_ABORTED = "CanceledError";
const REQUEST_CANCELED_CODE = "ECONNABORTED";
let abortController = new AbortController();

export const isRequestAborted = (err: unknown) => {
  return err instanceof Error && err.name === REQUEST_ABORTED;
};

export const isRequestCanceled = (error: ResponseError) => {
  return error.code === REQUEST_CANCELED_CODE;
};

export const getAbortController = () => abortController;

export const abortRequest = () => {
  try {
    abortController.abort();
  } catch (err) {
    if (!isRequestAborted(err)) {
      throw err;
    }
  } finally {
    abortController = new AbortController();
  }
};
