export enum PaymentMethod {
  CARD = "CARD",
  PAYPAL = "PAYPAL",
  SOFORT = "SOFORT",
  FPX = "FPX",
  IDEAL = "IDEAL",
  UNKNOWN = "UNKNOWN",
}

export enum CardProvider {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  AMERICAN_EXPRESS = "AMEX",
}
