import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { WithTypeContaining } from "../../../types/utilityTypes";

export enum MemberpackObtainingMethod {
  MEMBERPACK_OBTAINED_OUTSIDE_OA = "MEMBERPACK_OBTAINED_OUTSIDE_OA",
  MEMBERPACK_NOT_REQUIRED = "MEMBERPACK_NOT_REQUIRED",
  MEMBERPACK_PURCHASE_VIA_OA = "MEMBERPACK_PURCHASE_VIA_OA",
}

export type HmpOwnershipState = {
  supportedMemberpackObtainingMethods: MemberpackObtainingMethod[];
  memberpackObtainingMethod: MemberpackObtainingMethod | null;
};

export type OnlineCodeState = {
  onlineCodeConfirmed: boolean;
  idNumber: string;
  onlineCode: string;
};

export type HmpOwnershipValuesState = {
  memberpackObtainingMethod: MemberpackObtainingMethod | null;
  idNumber: string;
  onlineCode: string;
};

interface HmpOwnershipAction {
  setHmpOwnership: (
    hmpOwnershipValues: WithTypeContaining<HmpOwnershipValuesState>
  ) => void;
  verifyOnlineCode: () => void;
  isOnlineCodeVerified: () => boolean;
  clearOnlineCodeVerification: () => void;
  setSupportedMemberpackObtainingMethods: (
    supportedMemberpackObtainingMethods: MemberpackObtainingMethod[]
  ) => void;
  setMemberpackObtainingMethod: (
    memberpackObtainingMethod: MemberpackObtainingMethod
  ) => void;
  reset: () => void;
}

const initialState: HmpOwnershipState & OnlineCodeState = {
  memberpackObtainingMethod: null,
  onlineCodeConfirmed: false,
  idNumber: "",
  onlineCode: "",
  supportedMemberpackObtainingMethods: [],
};

export const useHmpOwnershipStore = create<
  HmpOwnershipState & OnlineCodeState & HmpOwnershipAction
>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setHmpOwnership: (hmpOwnershipValues) => {
          set(createHmpOwnershipFromForm(hmpOwnershipValues));
        },
        isOnlineCodeVerified: () => get().onlineCodeConfirmed,
        verifyOnlineCode: () => set({ onlineCodeConfirmed: true }),
        clearOnlineCodeVerification: () =>
          set({
            onlineCodeConfirmed: false,
          }),
        setSupportedMemberpackObtainingMethods: (
          supportedMemberpackObtainingMethods
        ) => {
          const isOnlyOneMemberpackOwnershipSupported =
            supportedMemberpackObtainingMethods.length === 1;
          set({
            supportedMemberpackObtainingMethods,
            ...(isOnlyOneMemberpackOwnershipSupported
              ? {
                  memberpackObtainingMethod:
                    supportedMemberpackObtainingMethods[0],
                }
              : {}),
          });
        },
        setMemberpackObtainingMethod: (memberpackObtainingMethod) =>
          set({ memberpackObtainingMethod }),
        reset: () => set(initialState),
      }),
      {
        name: "hmp-ownership-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const createHmpOwnershipFromForm = ({
  memberpackObtainingMethod,
  idNumber,
  onlineCode,
}: WithTypeContaining<HmpOwnershipValuesState>) => {
  if (!memberpackObtainingMethod) {
    throw new Error("Memberpack obtaining method is required");
  }

  return {
    memberpackObtainingMethod,
    idNumber,
    onlineCode,
  };
};
