import React, { startTransition, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PhoneVerification } from "./PhoneVerification";
import { useSponsorshipStore } from "./sponsorship/sponsorshipStore";
import { SponsorInformation } from "../../containers/SponsorInformation/SponsorInformation";
import styles from "./StartReistration.module.scss";
import { useProfileStore } from "./profileStore";
import { ShowValidSection } from "../../components/common/ShowValidSection";
import { Button } from "../../components/common/Button";
import { useRegistrationStore } from "../registration/registrationStore";
import { useShallow } from "zustand/react/shallow";
import { lazyWithPreload } from "../../utils/lazyLoading";

const PersonalInfoPage = lazyWithPreload(
  () => import("../../pages/PersonalInfo/PersonalInfoPage")
);

export const StartRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirmedSponsorId = useSponsorshipStore(
    useShallow((state) => state.confirmedSponsorId)
  );
  const readOnlyRegistration = useRegistrationStore(
    useShallow((state) => state.readOnlyMode)
  );
  const isPhoneEditable = useProfileStore(
    useShallow((state) => state.isPhoneEditable)
  );

  useEffect(() => {
    void PersonalInfoPage.preload();
  }, []);

  const redirectToPersonalInfo = () =>
    startTransition(() => navigate("/personalinfo"));

  return (
    <div className={styles.startRegistrationWrapper}>
      <SponsorInformation />
      {confirmedSponsorId && isPhoneEditable() && <PhoneVerification />}
      {confirmedSponsorId && !isPhoneEditable() && (
        <ShowValidSection isDataSubmitted={readOnlyRegistration}>
          <Button id="profile-button" onClick={redirectToPersonalInfo}>
            {t("CONTINUE")}
          </Button>
        </ShowValidSection>
      )}
    </div>
  );
};
