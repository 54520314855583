import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import "./index.scss";
import "./config/axios";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactErrorBoundary } from "./components/ReactErrorBoundary/ReactErrorBoundary";
import { App } from "./components/App/App";
import { queryClient } from "./config/queryClient";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactErrorBoundary>
        <App />
      </ReactErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
