import React, { useTransition } from "react";
import { useNavigate } from "react-router";
import { MembershipAgreement } from "../../containers/MembershipAgreement";
import {
  MemberpackObtainingMethod,
  useHmpOwnershipStore,
} from "../../features/personal-info/hmpOwnership/hmpOwnershipStore";
import { useHmpOwnedReadyMutation } from "../../features/online-code-registration/hmpOwnedReadyMutation";
import { useRegistrationStore } from "../../features/registration/registrationStore";
import { Spinner } from "../../components/common/Spinner";
import { useEventStore } from "../../features/events/eventStore";
import { useShallow } from "zustand/react/shallow";
import { RegistrationEvent } from "../../features/events/models/RegistrationEvent";

const MembershipAgreementPage = () => {
  const navigate = useNavigate();
  const memberpackObtainingMethod = useHmpOwnershipStore(
    useShallow((state) => state.memberpackObtainingMethod)
  );
  const hmpOwnedReadyMutation = useHmpOwnedReadyMutation();
  const readOnlyRegistration = useRegistrationStore(
    useShallow((state) => state.readOnlyMode)
  );
  const [isPending, startTransition] = useTransition();
  const publish = useEventStore(useShallow((state) => state.publish));

  const noPaymentRequired =
    memberpackObtainingMethod != null &&
    [
      MemberpackObtainingMethod.MEMBERPACK_OBTAINED_OUTSIDE_OA,
      MemberpackObtainingMethod.MEMBERPACK_NOT_REQUIRED,
    ].includes(memberpackObtainingMethod);

  const handleAccept = () => {
    if (noPaymentRequired) {
      hmpOwnedReadyMutation.mutate(undefined, {
        onSuccess: () => {
          publish(RegistrationEvent.AgreementsAccepted);
          publish(RegistrationEvent.RegistrationCompleted);
          startTransition(() => navigate("/confirmation"));
        },
      });
    } else {
      publish(RegistrationEvent.AgreementsAccepted);
      startTransition(() => navigate("/payment"));
    }
  };

  return (
    <>
      <Spinner isShowing={isPending} />
      <MembershipAgreement
        isDataSubmitted={readOnlyRegistration}
        loading={hmpOwnedReadyMutation.isPending}
        isError={hmpOwnedReadyMutation.isError}
        onAccept={handleAccept}
      />
    </>
  );
};

export default MembershipAgreementPage;
