import { StringSchema } from "yup";
import dayjs, { Dayjs } from "dayjs";
import { isSafari } from "../../utils/detect";
import { convertDateFormat } from "../../utils/convertDateFormat";

export function filterForbiddenWordValidation(
  this: StringSchema,
  forbiddenWords: string[],
  errorMessage: string
) {
  return this.test(
    "forbidden-words",
    errorMessage,
    (value) =>
      !forbiddenWords.some((forbiddenWord) =>
        value?.toLocaleLowerCase()?.includes(forbiddenWord.toLowerCase())
      )
  );
}

export function patternValidation(
  this: StringSchema,
  pattern: RegExp,
  errorMessage: string
) {
  return this.test("pattern-validation", errorMessage, (value) =>
    value && pattern ? pattern.test(value || "") : true
  );
}

export function matchesDynamicPattern(
  this: StringSchema,
  dependencyFieldName: string,
  getPattern: (value: string) => RegExp | null,
  errorMessage: string
) {
  return this.test(
    "dynamic-pattern-validation",
    errorMessage,
    function validate(value) {
      const parent = this.parent as Record<string, unknown>;
      const dependencyFieldValue: unknown = parent[dependencyFieldName];
      const pattern = getPattern(dependencyFieldValue as string);

      return value && pattern ? new RegExp(pattern).test(value || "") : true;
    }
  );
}

export function minDateValidation(
  this: StringSchema,
  minDate: Dayjs,
  errorMessage: string
) {
  return this.test(
    "min-date-validation",
    errorMessage,
    function validate(value) {
      if (!value) {
        return true;
      }

      let date;
      if (isSafari) {
        date = convertDateFormat(value);
      } else {
        date = value;
      }

      return dayjs(date).isAfter(minDate);
    }
  );
}

export function maxDateValidation(
  this: StringSchema,
  maxDate: Dayjs,
  errorMessage: string
) {
  return this.test(
    "max-date-validation",
    errorMessage,
    function validate(value) {
      if (!value) {
        return true;
      }

      let date;
      if (isSafari) {
        date = convertDateFormat(value);
      } else {
        date = value;
      }

      return dayjs(date).isBefore(maxDate);
    }
  );
}
