import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  Gender,
  ONLY_DIGITS_REGEX,
} from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
} from "./defaults";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import {
  ApliLogo,
  MastercardLogo,
  VisaLogo,
} from "../../components/common/Logo/models/Logo";
import { queryClient } from "../queryClient";
import { AdministrativeAreasResponse, avsApi } from "../../api/avs";
import dayjs from "dayjs";

import { SanitizeMode } from "../next-gen-config-wip";

const NAMES_REGEX = new RegExp(/^[A-Za-z ]*$/);
const INDONESIAN_ADDRESS_LINE_PATTERN = RegExp(
  /^[\p{Lower}\p{Upper}\s\d]*$/u,
  "u"
);

const getAddressData = async (areas: string[] = []) =>
  await queryClient.fetchQuery({
    queryKey: ["id-ID", areas],
    queryFn: async (): Promise<AdministrativeAreasResponse> =>
      await avsApi.getAdministrativeAreas("id-ID", areas, 0, 100),
    staleTime: Infinity,
    gcTime: Infinity,
  });

const getAddresses = async (areas: string[]) =>
  (await getAddressData(areas)).values;

const getProvinces = async (): Promise<string[]> => await getAddresses([]);

const getCities = async (selectedProvince: string): Promise<string[]> =>
  await getAddresses([selectedProvince]);

const getDistricts = async (
  selectedProvince: string,
  selectedCity: string
): Promise<string[]> => await getAddresses([selectedProvince, selectedCity]);

const getSubdistricts = async (
  selectedProvince: string,
  selectedCity: string,
  selectedDistrict: string
): Promise<string[]> =>
  await getAddresses([selectedProvince, selectedCity, selectedDistrict]);

const getPostalCodes = async (
  selectedProvince: string,
  selectedCity: string,
  selectedDistrict: string,
  selectedSubdistrict: string
): Promise<string[]> =>
  await getAddresses([
    selectedProvince,
    selectedCity,
    selectedDistrict,
    selectedSubdistrict,
  ]);

export const Indonesia: CountryFormConfiguration = {
  id: "id-ID",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-Z\s]/g, ""),
          },
        ],
        bottomDescription: { text: "FIRST_NAME_DISCLAIMER" },
        validation: {
          required: { value: false },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: NAMES_REGEX,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-Z\s]/g, ""),
          },
        ],
        bottomDescription: { text: "LAST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: NAMES_REGEX,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "radio",
        options: [
          { value: Gender.MALE, label: "MALE" },
          { value: Gender.FEMALE, label: "FEMALE" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        validationErrorStyling: ["textCentered"],
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: INDONESIAN_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          pattern: {
            value: INDONESIAN_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: getProvinces,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province"],
        getOptions: getCities,
        rule: {
          visibleOn: ["province", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city"],
        getOptions: getDistricts,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city", "district"],
        getOptions: getSubdistricts,
        rule: {
          visibleOn: ["district", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city", "district", "subdistrict"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["subdistrict", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 16,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 16,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: ONLY_DIGITS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          {
            mode: SanitizeMode.ON_CHANGE,
            sanitizeFn: (value) => value.replace(/[^a-zA-Z\s]/g, ""),
          },
        ],
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "REQUIRED",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.careOfName,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
          pattern: {
            value: NAMES_REGEX,
            errorMessage: "REQUIRED",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: INDONESIAN_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          pattern: {
            value: INDONESIAN_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: getProvinces,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province"],
        getOptions: getCities,
        rule: {
          visibleOn: ["province", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city"],
        getOptions: getDistricts,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city", "district"],
        getOptions: getSubdistricts,
        rule: {
          visibleOn: ["district", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["province", "city", "district", "subdistrict"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["subdistrict", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: INDONESIAN_ADDRESS_LINE_PATTERN,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "00000",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: 40,
  provinceRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Indonesia",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLinks: [
    "TERMS",
    "PRIVACY",
    "COOKIE_POLICY",
    "RULES_OF_CONDUCT",
    "COPYRIGHT",
  ],
  footerLogos: [VisaLogo, MastercardLogo, ApliLogo],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  showDataPrivacyAcknowledgment: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["id"],
  defaultCountryCode: "+62|Indonesia",
  showSponsorshipDisclaimer: true,
} as const;
