import { vdvLogo } from "../../components/common/Logo/models/Logo";

import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultForbiddenWordsValidation,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";
import dayjs from "dayjs";
import { sanitizeStringValue } from "../../utils/replacement";
import { SanitizeMode } from "../next-gen-config-wip";

const DUTCH_POSTAL_CODE_PATTERN = /^\d{4}\s[a-zA-Z]{2}$/;

export const Netherlands: CountryFormConfiguration = {
  id: "nl-NL",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 20,
            errorMessage: "FIRST_NAME_SIZE",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 30,
            errorMessage: "LAST_NAME_SIZE",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(18, "year"),
            errorMessage: "TOO_YOUNG",
          },
          minDate: {
            value: dayjs().subtract(120, "year"),
            errorMessage: "TOO_OLD",
          },
        },
      },
      houseNumber: {
        name: "addressLine2",
        label: "HOUSE_NUMBER",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
        classNames: ["halfScreenWidth"],
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          pattern: {
            value: DUTCH_POSTAL_CODE_PATTERN,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "0000 aa",
        },
        classNames: ["halfScreenWidth"],
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "ADDRESS_SIZE" },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
        rule: {
          isDisabled: true,
          enableOn: ["experianError"],
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
        rule: {
          isDisabled: true,
          enableOn: ["experianError"],
        },
      },
      dataPrivacy: {
        name: "dataPrivacy",
        header: "DATA_PRIVACY_HEADER",
        type: "static",
        staticValue: "DATA_PRIVACY_STATEMENT",
      },
      dataPrivacyDisclosure: {
        name: "dataPrivacyDisclosure",
        type: "static",
        staticValue: "INFORMATION_DISCLOSURE",
      },
      additionalComunicationConsent: {
        name: "additionalComunicationConsent",
        type: "static",
        staticValue: "ADDITIONAL_COMMUNICATION_CONSENT",
      },
      marketingConsentAccepted: {
        name: "marketingConsentAccepted",
        label: "EXTRA_COMMUNICATION_CONSENT",
        type: "checkbox",
        validation: {
          required: { value: false },
        },
      },
      prospectOwnsSignificantSocialMediaAccount: {
        name: "prospectOwnsSignificantSocialMediaAccount",
        label: "SOCIAL_MEDIA_FOLLOWERS_QUESTION",
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      socialMediaConsents: {
        name: "socialMediaConsentsWrapper",
        label: "SOCIAL_MEDIA",
        type: "object",
        classNames: ["collapse"],
        rule: {
          visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
        },
        validation: {
          required: { value: false },
        },
        isCollapsable: true,
        fields: {
          customerAcknowledgementInfo: {
            name: "customerAcknowledgementInfo",
            type: "static",
            staticValue: "CUSTOMER_ACKNOWLEDGEMENT_INFO",
          },
          socialMediaConsent0: {
            name: "socialMediaConsent0",
            label: "CUSTOMER_SERVICE",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CONTACT_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent1: {
            name: "socialMediaConsent1",
            label: "CUSTOMER_LEAD",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "CUSTOMER_LEAD_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent2: {
            name: "socialMediaConsent2",
            label: "SALES_AND_MARKETING_PLAN",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SALES_AND_MARKETING_PLAN_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent3: {
            name: "socialMediaConsent3",
            label: "NO_ASSOCIATION",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "NO_ASSOCIATION_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent4: {
            name: "socialMediaConsent4",
            label: "SOCIAL_MEDIA",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "SOCIAL_MEDIA_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaConsent5: {
            name: "socialMediaConsent5",
            label: "EARNINGS_CLAIMS",
            type: "checkbox",
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
            bottomDescription: { text: "EARNINGS_CLAIMS_CONSENT" },
            rule: {
              visibleOn: ["prospectOwnsSignificantSocialMediaAccount", Boolean],
            },
            defaultValue: false,
          },
          socialMediaEndingNote: {
            name: "socialMediaEndingNote",
            type: "static",
            staticValue: "SOCIAL_MEDIA_ENDING_NOTE",
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: [
          { mode: SanitizeMode.ON_CHANGE, sanitizeFn: sanitizeStringValue },
        ],
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      houseNumber: {
        name: "addressLine2",
        label: "HOUSE_NUMBER",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          pattern: {
            value: DUTCH_POSTAL_CODE_PATTERN,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "0000 aa",
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "ADDRESS_SIZE" },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          forbiddenWords: defaultForbiddenWordsValidation,
          trim: true,
        },
        rule: {
          isDisabled: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
        rule: {
          isDisabled: true,
        },
      },
    },
  },
  addressLength: 40,
  cityLength: 30,
  postalCodeLength: 7,
  postalCodeMask: "0000 AA",
  postalCodePattern: DUTCH_POSTAL_CODE_PATTERN,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLinks: ["PRIVACY", "COOKIE_POLICY", "RULES_OF_CONDUCT", "COPYRIGHT"],
  footerLogos: [vdvLogo],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showSocialMediaStatement: true,
  supportedCountryCodes: ["nl", "be", "de"],
  defaultCountryCode: "+31|Netherlands",
} as const;
